import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getGPRDataService,
  getGPRGraphDataService,
  getLostLeasesDataService,
  getOccupancyDataService,
  getRenewalDataService,
  getTourConversionDataService,
  getTourDataService,
  getGoalsDisplayDataService,
  getGoalsDataService,
  updateGoalsDataService
} from '../../services/stats.service';

import {
  parseGPRGraphData,
  parseGPRData,
  parseLostLeasesData,
  parseOccupancyData,
  parseRenewalsData,
  parseTourConversionData,
  parseTourData,
  parseGoalsDisplayData,
  parseGoalsData,
  parseUpdateGoalsData
} from './stats-utils';

export const getGPRStats = createAsyncThunk(
  'stats/gpr-data',
  async (_, { getState }: any) => {
    try {
      const result: any = await getGPRDataService(getState().user.token);
      // console.log('getGPRStats/result', result);

      const parsedData = parseGPRData(result.data);
      // console.log('getGPRStats/parsedData', parsedData);

      return { gpr: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getGPRGraphData = createAsyncThunk(
  'stats/gpr-graph',
  async (_, { getState }: any) => {
    try {
      const result: any = await getGPRGraphDataService(getState().user.token);
      // console.log('getGPRGraphData/result', result);

      const parsedData = parseGPRGraphData(result.data);
      // console.log('getGPRGraphData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getLostLeasesStats = createAsyncThunk(
  'stats/lost-leases',
  async (_, { getState }: any) => {
    try {
      const result: any = await getLostLeasesDataService(getState().user.token);
      // console.log('getLostLeasesStats/result: ', result);

      const parsedData = parseLostLeasesData(result.data);
      // console.log('getLostLeasesStats/parsedData', parsedData);

      return { lostLeases: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getOccupancyStats = createAsyncThunk(
  'stats/occupancy',
  async (_, { getState }: any) => {
    try {
      const result: any = await getOccupancyDataService(getState().user.token);
      // console.log('getOccupancyStats: ', result);

      const parsedData = parseOccupancyData(result.data);
      // console.log('getOccupancyStats/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getRenewalStats = createAsyncThunk(
  'stats/renewals',
  async (_, { getState }: any) => {
    try {
      const result: any = await getRenewalDataService(getState().user.token);
      console.log('getRenewalStats: ', result);

      const parsedData = parseRenewalsData(result.data);
      console.log('getRenewalStats/parsedData', parsedData);

      return { renewals: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getTourConversionStats = createAsyncThunk(
  'stats/tourconversion',
  async (_, { getState }: any) => {
    try {
      const result: any = await getTourConversionDataService(
        getState().user.token
      );
      // console.log('getTourConversionStats: ', result);

      const parsedData = parseTourConversionData(result.data);
      // console.log('getTourConversionStats/parsedData', parsedData);
      return { tourConversion: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getTourStats = createAsyncThunk(
  'stats/tours',
  async (_, { getState }: any) => {
    try {
      const result: any = await getTourDataService(getState().user.token);
      // console.log('getTourStats: ', result);

      const parsedData = parseTourData(result.data);
      // console.log('getTourStats/parsedData', parsedData);

      return { tours: parsedData };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getGoalsData = createAsyncThunk(
  'stats/goals/get',
  async (_, { getState }: any) => {
    try {
      const result: any = await getGoalsDataService(getState().user.token);
      // console.log('getGoalsData: ', result);

      const parsedData = parseGoalsData(result.data);
      // console.log('getGoalsData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getGoalsDisplayData = createAsyncThunk(
  'stats/goals/display/get',
  async (_, { getState }: any) => {
    try {
      const result: any = await getGoalsDisplayDataService(
        getState().user.token
      );
      // console.log('getGoalsDisplayData: ', result);

      const parsedData = parseGoalsDisplayData(result.data);
      // console.log('getGoalsDisplayData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const updateGoalsData = createAsyncThunk(
  'stats/goals/update',
  async (data: any, { dispatch, getState }: any) => {
    try {
      const body = parseUpdateGoalsData(data);
      console.log('updateGoalsData/body', body);

      const result: any = await updateGoalsDataService(
        body,
        getState().user.token
      );
      console.log('updateGoalsData/result: ', result);

      dispatch(getGoalsData());

      return { success: true };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
