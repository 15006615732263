import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useEffect, useState } from "react";
import { Typography } from "antd";
import withResponsiveMaxWidth from "../../hoc/withResponsiveMaxWidth";

interface SeriesData {
  data: any[];
  name: string;
  type: string;
}

interface ChartData {
  series: SeriesData[];
  options: ApexOptions;
}

const linearRegression = (data: any[]) => {
  const n = data.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;

  data.forEach((point) => {
    sumX += point.x;
    sumY += point.y;
    sumXY += point.x * point.y;
    sumXX += point.x * point.x;
  });

  const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return { slope, intercept };
};

const ScatterPlot = ({
  data,
  title,
  height,
  filterDataHandler,
}: {
  data: {
    filteredChartData: ChartDataItem[];
    uniqueProperties: string[];
    selectedUniqueProperty: string;
    key: number;
    colors: string[];
    legendColors: string[];
    showSelectedProperty: boolean;
    propertyOptions: Item[];
    selectedPropertyId: string;
  };
  title?: string;
  height?: number;
  filterDataHandler: (property: string, i: number) => void;
}) => {
  const property = data?.propertyOptions.find(
    (item) => item?.value === data?.selectedPropertyId
  );

  const { slope, intercept } = linearRegression(
    data.filteredChartData?.filter((item: any) => item !== property?.label)
  );

  const startX = Math.min(
    ...data.filteredChartData
      ?.filter((item: any) => item !== property?.label)
      .map((point: any) => point.x)
  );

  const endX = Math.max(
    ...data.filteredChartData
      ?.filter((item: any) => item !== property?.label)
      .map((point: any) => point.x)
  );
  const regressionLine = [
    { x: startX, y: slope * startX + intercept },
    { x: endX, y: slope * endX + intercept },
  ];

  const generateSeries = () => {
    const series: SeriesData[] = [];

    data.uniqueProperties
      ?.filter(
        (item: any) => data?.showSelectedProperty || item !== property?.label
      )
      .forEach((property) => {
        const propertyData = data.filteredChartData.filter(
          (point) => point.property === property
        );
        if (propertyData.length > 0) {
          series.push({
            name: property,
            type: "scatter",
            data: propertyData,
          });
        }
      });

    series.push({
      name: "Regression Line",
      type: "line",
      data: regressionLine,
    });

    return series;
  };

  const [chartData, setChartData] = useState<ChartData>({
    series: generateSeries(),
    options: {
      chart: {
        type: "line",
        height: height || 400,
        toolbar: {
          show: false,
        },
      },
      fill: {
        type: "solid",
      },
      markers: {
        size: 6,
      },
      grid: {
        show: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const point =
            w.globals.initialSeries[seriesIndex].data[dataPointIndex];
          const seriesColor = w.globals.colors[seriesIndex]; // Get series color

          return `
            <div style="background-color: #fff; border: 1px solid ${seriesColor}; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); padding: 10px; font-family: Arial, sans-serif; font-size: 14px; line-height: 1.6; max-width: 250px;">
              <span style="font-weight: bold; color: ${seriesColor};">${
            w.globals.seriesNames[seriesIndex]
          }</span><br />
              <span style="color: #555; font-weight: bold; font-size: 13px">Market Rent:</span> <strong>$${point.y.toLocaleString()}</strong><br />
              <span style="color: #555; font-weight: bold; font-size: 13px">Unit Size:</span> <strong>${point.x.toLocaleString()} SqFt</strong><br />
              <span style="color: #555; font-weight: bold; font-size: 13px">Floor Plan:</span> <strong>${
                point.floorplan_name ? point.floorplan_name?.toString() : ""
              }</strong><br />
              <span style="color: #555; font-weight: bold; font-size: 13px">Unit Number:</span> <strong>${
                point.unit_number ? point.unit_number?.toString() : ""
              }</strong>
            </div>
          `;
        },
      },
      legend: {
        show: false,
        onItemClick: {
          toggleDataSeries: true,
        },
      },
      colors: data.colors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        curve: "straight",
        lineCap: "butt",
        colors: ["#4361F9"],
        width: 4,
        dashArray: 0,
      },
      title: {
        text: title || "Total Monthly Lease Value",
        style: {
          fontWeight: 600,
          fontFamily: "Poppins",
          fontSize: "18px",
        },
      },
      yaxis: {
        title: {
          text: "Price",
          style: {
            color: "#8390A2",
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Poppins",
          },
        },
        axisBorder: {
          show: true,
          color: "#CFD4DB",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          formatter: function (value: any) {
            return `$${Math.round(value).toLocaleString()}`;
          },
          style: {
            colors: "#8390A2",
            fontWeight: 400,
            fontFamily: "Poppins",
            fontSize: "14px",
          },
        },
        min:
          Math.min(...data.filteredChartData.map((point: any) => point.y)) *
          0.9,
        max:
          Math.max(...data.filteredChartData.map((point: any) => point.y)) *
          1.1,
      },
      xaxis: {
        title: {
          text: "Square Feet",
          style: {
            color: "#8390A2",
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Poppins",
          },
        },

        axisBorder: {
          show: true,
          color: "#CFD4DB",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          formatter: function (value: any) {
            return `${Math.round(value).toLocaleString()}`;
          },
          style: {
            colors: "#8390A2",
            fontWeight: 400,
            fontFamily: "Poppins",
            fontSize: "14px",
          },
          rotate: 45,
          offsetX: -3,
        },
        min:
          Math.min(...data.filteredChartData.map((point: any) => point.x)) *
          0.9,
        max:
          Math.max(...data.filteredChartData.map((point: any) => point.x)) *
          1.1,
      },
    },
  });

  useEffect(() => {
    setChartData({
      ...chartData,
      series: generateSeries(),
    });
  }, [data.filteredChartData, data.uniqueProperties]);

  if (data.filteredChartData.length === 0) {
    return (
      <div style={{ minHeight: "100px" }}>
        <Typography.Title level={5} style={{ marginTop: 0 }}>
          {title}
        </Typography.Title>
        <Typography.Text style={{ color: "lightgray" }}>
          No data
        </Typography.Text>
      </div>
    );
  }

  // @ts-ignore
  const index = data.uniqueProperties.indexOf(property && property?.label);
  if (index > -1) {
    data.uniqueProperties.splice(index, 1);
    // @ts-ignore
    data.uniqueProperties.unshift(property && property?.label);
  }

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={{
            ...chartData.options,
            chart: {
              ...chartData.options.chart,
            },
          }}
          series={chartData.series}
          type="bar"
          height={height || 400}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 15,
            maxWidth: "80%",
            margin: "auto",
          }}
        >
          {
            // @ts-ignore
            data.uniqueProperties
              ?.filter((prop) =>
                data?.showSelectedProperty ? true : prop !== property?.label
              )
              .map((item: string, i: number) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                    cursor: "pointer",
                    opacity: data.selectedUniqueProperty === item ? 0.2 : 1,
                  }}
                  id={item}
                  // @ts-ignore
                  onClick={() => filterDataHandler(item, i)}
                >
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      background: `${data.legendColors[i]}`,
                    }}
                  ></div>
                  <span
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {item}
                  </span>
                </div>
              ))
          }
        </div>
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ScatterPlot;
