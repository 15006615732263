import { useCallback, useEffect, useMemo } from 'react';

import StatCard from '../../components/stats/StatCard';
// import StatLoader from './StatLoader';

import { useAppDispatch, useAppSelector } from '../../store/hooks';

import { getLostLeasesStats } from '../../store/stats/stats-slice';

interface LostLeasesProps {
  propertyID: string;
}

// dev only - remove when API is ready
const defaultData = {
  title: 'Net Leases',
  barData: {
    value: 0,
    total: 0
  },
  topData: {
    label: 'Approved',
    value: 0
  },
  bottomData: {
    label: 'Cancel/Deny',
    value: 0
  }
};

const LostLeases = ({ propertyID }: LostLeasesProps): ReactNode => {
  const dispatch = useAppDispatch();
  const lostLeases: any = useAppSelector((state) => state.stats.lostLeases);

  const selectedPropertyData = useMemo(() => {
    if (lostLeases.isLoading || lostLeases.data.length === 0) {
      return defaultData;
    }

    if (!propertyID && !lostLeases.isLoading && lostLeases.data.length > 0) {
      return defaultData;
    }

    const selectedData = lostLeases.data.find(
      (property: any) => property.propertyID === propertyID
    );

    return selectedData ? selectedData : lostLeases.data[0];
  }, [propertyID, lostLeases]);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(getLostLeasesStats());
    } catch (error) {
      console.log('error:', error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      {/* <StatLoader isLoading={lostLeases.isLoading} /> */}
      <StatCard
        data={selectedPropertyData ? selectedPropertyData : defaultData}
      />
    </>
  );
};

export default LostLeases;
