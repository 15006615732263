import Chart from 'react-apexcharts';

import { purple300, grey700, indigo500, orange500 } from './chartColors';

import styles from './stats.module.scss';

const defaultGraphData = new Array(12).fill(0);

const getGraphData = (data) => {
  const graphData = data.map((month) =>
    Number((month.value / 1000).toFixed(1))
  );

  if (graphData.length < 12) {
    // fill in the rest of the months with 0 to always display 12 months
    const emptyMonths = new Array(12 - graphData.length).fill(0);
    return [...graphData, ...emptyMonths];
  } else {
    return graphData;
  }
};

const OccupancyGPRChart = ({ data }) => {
  const { gprGraph, occupancy } = data;

  const budgetData =
    gprGraph?.budget && gprGraph?.budget.length > 0
      ? getGraphData(gprGraph?.budget)
      : defaultGraphData;

  const actualData =
    gprGraph?.actual && gprGraph?.actual.length > 0
      ? getGraphData(gprGraph?.actual)
      : defaultGraphData;

  const occupancyData = occupancy
    ? occupancy.data.map((month) => month.value)
    : [];

  // console.log('budgetData: ', budgetData);
  // console.log('actualData: ', actualData);
  // console.log('occupancyData: ', occupancyData);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const maxLevel = Math.round(Math.max(...budgetData, ...actualData) * 1.025);
  // const minLevel = Math.round(Math.min(...actualData) * 0.8);
  const minLevel = Math.round(
    Math.min(...actualData.filter((value) => value !== 0)) * 0.975
  );
  // const minLevel = 50

  const chartHeight = 350;

  const chartSeries = [
    {
      name: 'Budget',
      type: 'column',
      data: budgetData
    },
    {
      name: 'Actual',
      type: 'column',
      data: actualData
    },
    {
      name: 'Occupancy',
      type: 'line',
      data: occupancyData
    }
  ];

  const chartOptions = {
    chart: {
      height: chartHeight,
      width: '100%',
      type: 'line',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        barHeight: '70%',
        barWidth: 30,
        borderRadius: 10,
        borderRadiusApplication: 'end',
        columnWidth: '72%'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: [4, 4, 4],
      colors: ['#fff', '#fff', purple300]
    },
    xaxis: {
      type: 'category',
      categories: months,
      labels: {
        show: true,
        style: {
          colors: grey700,
          fontFamily: 'Poppins, sans-serif',
          fontSize: '14px',
          fontWeight: 400
        }
      },
      tooltip: {
        enabled: false
      }
    },

    yaxis: [
      {
        show: true,
        seriesName: 'Budget',
        title: {
          text: 'EGR',
          style: {
            color: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '18px',
            fontWeight: '400'
          }
        },
        labels: {
          formatter: (val) => (val ? `${Math.round(val)}K` : ''),
          style: {
            colors: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '11px',
            fontWeight: 400
          }
        },
        min: minLevel || 0,
        max: maxLevel || 100
      },
      {
        show: false,
        seriesName: 'Actual',
        min: minLevel || 0,
        max: maxLevel || 100
      },
      {
        show: true,
        seriesName: 'Occupancy',
        title: {
          text: 'Occupancy',
          style: {
            color: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '18px',
            fontWeight: '400'
          }
        },
        labels: {
          formatter: (val) => (val ? `${Math.round(val)}%` : ''),
          style: {
            colors: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '11px',
            fontWeight: 400
          }
        },
        opposite: true,
        min: 85,
        max: 100
      }
    ],
    markers: {
      size: 4,
      strokeWidth: 0,
      fillOpacity: 0,
      hover: {
        size: 6
      }
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const seriesData = series[seriesIndex];
        const dataPoint = seriesData[dataPointIndex];

        if (dataPoint === 0) {
          return null;
        }
        const dataType =
          seriesIndex === 0
            ? 'budget'
            : seriesIndex === 1
            ? 'actual'
            : 'occupancy';

        const formattedDataPoint =
          seriesIndex === 2 ? `${dataPoint}%` : `${dataPoint}K`;

        return `
           <div class='${styles.occupancyTooltip}' data-type='${dataType}'>
            <div>${dataType}</div> 
            <div>${formattedDataPoint}</div> 
          </div>
          `;
      }
    },
    legend: {
      show: false
    },
    responsive: [
      {
        breakpoint: 1192,
        options: {
          plotOptions: {
            bar: {
              barWidth: 40,
              borderRadius: 6,
              columnWidth: '100%'
            }
          },
          stroke: {
            width: [3, 3, 4],
            colors: ['#fff', '#fff', purple300]
          }
        }
      },
      {
        breakpoint: 1300,
        options: {
          plotOptions: {
            bar: {
              barWidth: 40,
              borderRadius: 7,
              columnWidth: '100%'
            }
          },
          stroke: {
            width: [3, 3, 4],
            colors: ['#fff', '#fff', purple300]
          }
        }
      },
      {
        breakpoint: 1400,
        options: {
          plotOptions: {
            bar: {
              barWidth: 40,
              borderRadius: 8,
              columnWidth: '100%'
            }
          },
          stroke: {
            width: [3, 3, 4],
            colors: ['#fff', '#fff', purple300]
          }
        }
      },
      {
        breakpoint: 1543,
        options: {
          plotOptions: {
            bar: {
              barWidth: 30,
              borderRadius: 10,
              columnWidth: '100%'
            }
          },
          stroke: {
            width: [5, 5, 4],
            colors: ['#fff', '#fff', purple300]
          }
        }
      }
    ],
    colors: [indigo500, orange500, purple300]
  };

  return (
    <div id='chart-wrapper-occupancy-gpr' style={{ width: '100%' }}>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type={'line'}
        height={chartHeight}
      />
    </div>
  );
};
export default OccupancyGPRChart;
