import { useCallback, useEffect, useMemo } from "react";

import StatCard from "../../components/stats/StatCard";
import StatLoader from "./StatLoader";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { getTourStats } from "../../store/stats/stats-slice";

interface ToursProps {
  propertyID: string;
}

const defaultData = {
  title: "Tours",
  barData: {
    value: 0,
    total: 0,
  },
  topData: {
    label: "Projected Variance",
    value: 0,
  },
  bottomData: {
    label: "Actual Tour Count",
    value: 0,
  },
};

const Tours = ({ propertyID }: ToursProps): ReactNode => {
  const dispatch = useAppDispatch();
  const tours: any = useAppSelector((state) => state.stats.tours);

  const selectedPropertyData = useMemo(() => {
    if (tours.data.length === 0 || tours.isLoading) {
      return defaultData;
    }

    if (!propertyID && !tours.isLoading && tours.data.length > 0) {
      return defaultData;
    }

    const selectedData = tours.data.find(
      (property: any) => property.propertyID === propertyID
    );

    return selectedData ? selectedData : tours.data[0];
  }, [propertyID, tours]);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(getTourStats());
    } catch (error) {
      console.log("error:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <StatLoader isLoading={tours.isLoading} />
      <StatCard
        data={selectedPropertyData ? selectedPropertyData : defaultData}
      />
    </>
  );
};

export default Tours;
