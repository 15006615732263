const getMonthAbbrev = (monthNum) => {
  const monthAbbrev = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const monthIdx = monthNum - 1;

  return monthAbbrev[monthIdx];
};

export const initialMonthKeyValuePairs = {
  jan: 0,
  feb: 0,
  mar: 0,
  apr: 0,
  may: 0,
  jun: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  oct: 0,
  nov: 0,
  dec: 0,
};

export const getGoalsInitialData = () => ({
  propertyID: "",
  goals: {
    tours: initialMonthKeyValuePairs,
    tourConversionPct: 0,
    // renewal_percentage: initialMonthKeyValuePairs,
    // approved_leases: initialMonthKeyValuePairs,
    renewalPct: 0,
  },
});

const roundNumberToTwoDecimals = (num) => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const convertNumArrayToStr = (array) => {
  try {
    const serializedStr = array.map((item) => item.toString()).join(",");
    console.log("serializedStr: ", serializedStr);

    return serializedStr;
  } catch (error) {
    console.log("convertStrToNumArray/error: ", error);
    return null;
  }
};

export const convertStrToNumArray = (str) => {
  try {
    const numArray = str.split(",").map((item) => Number(item));
    // console.log('numArray: ', numArray);

    return numArray;
  } catch (error) {
    console.log("convertStrToNumArray/error: ", error);
    return null;
  }
};

const getPropertyGPRGraphData = (data) => {
  const sorted = data.months.toSorted((a, b) => a.Month - b.Month);
  const actual = sorted.map((item) => ({
    month: getMonthAbbrev(item.Month),
    value: item.gpr_actual,
  }));
  const budget = sorted.map((item) => ({
    month: getMonthAbbrev(item.Month),
    value: item.gpr_budget,
  }));
  const gprGraphData = {
    propertyID: data.propertyID,
    actual,
    budget,
  };

  return gprGraphData;
};

export const parseGPRGraphData = (data) => {
  try {
    let properties = [];

    data.gpr_graph.forEach((item) => {
      const propertyIdx = properties.findIndex(
        (property) => property.propertyID === item.PropertyID
      );

      if (propertyIdx === -1) {
        const propertyData = {
          propertyID: item.PropertyID,
          months: [item],
        };
        properties.push(propertyData);
      } else {
        const target = properties[propertyIdx];
        target.months.push(item);
        properties[propertyIdx] = target;
      }
    });

    const gprGraphData = properties.map((property) =>
      getPropertyGPRGraphData(property)
    );

    return gprGraphData;
  } catch (error) {
    console.log("parseGPRGraphData error: ", error);
    return null;
  }
};

export const parseGPRData = (data) => {
  try {
    const gprData = data.gpr_card.map((item) => {
      const propertyData = {
        propertyID: item.PropertyID,
        ytd: {
          actual: roundNumberToTwoDecimals(item.ytd_gpr_actual),
          budget: roundNumberToTwoDecimals(item.ytd_gpr_budget),
          variance: roundNumberToTwoDecimals(item.ytd_gpr_variance),
        },
        annualized: {
          actual: roundNumberToTwoDecimals(item.annualized_gpr_actual),
          budget: roundNumberToTwoDecimals(item.annualized_gpr_budget),
          variance: roundNumberToTwoDecimals(item.annualized_gpr_variance),
        },
      };

      return propertyData;
    });
    // console.log('gprData: ', gprData);

    return gprData;
  } catch (error) {
    console.log("parseGPRData/error: ", error);
    return null;
  }
};

export const parseLostLeasesData = (data) => {
  try {
    // console.log('parseLostLeasesData/data', data);
    const lostLeasesData = data.lost_leases.map((item) => {
      const total =
        item.approved_cd_goal === "NULL" ? 0 : item.approved_cd_goal;

      const propertyData = {
        propertyID: item.propertyid,
        title: "Net Leases",
        barData: {
          value: Number(item.approved_cd_ratio.toFixed(2)),
          total,
        },
        topData: {
          label: "Approved",
          value: item.approved_leases,
        },
        bottomData: {
          label: "Cancel/Deny",
          value: item.cd_leases,
        },
      };

      return propertyData;
    });
    // console.log('lostLeasesData: ', lostLeasesData);

    // filter out properties with short ids
    const netLeasesData = lostLeasesData.filter(
      (item) => item.propertyID.length > 4
    );

    return netLeasesData;
  } catch (error) {
    console.log("parseLostLeasesData/error: ", error);
    return null;
  }
};

const getPropertyOccupancyData = (data) => {
  const occupancyData = {
    propertyID: data.propertyID,
    data: [],
  };

  const sortedData = data.months.toSorted((a, b) => a.month - b.month);

  const items = sortedData.map((item) => ({
    month: getMonthAbbrev(item.month),
    value: Math.round(item.occupancy_perc),
  }));

  occupancyData.data = items;

  return occupancyData;
};

export const parseOccupancyData = (data) => {
  try {
    let properties = [];

    data.occupancy_percentage.forEach((item) => {
      // filter out properties with short ids
      if (item.PropertyID.length < 5) {
        return;
      }

      const propertyIdx = properties.findIndex(
        (property) => property.propertyID === item.PropertyID
      );

      if (propertyIdx === -1) {
        const propertyData = {
          propertyID: item.PropertyID,
          months: [item],
        };

        properties.push(propertyData);
      } else {
        const target = properties[propertyIdx];

        target.months.push(item);

        properties[propertyIdx] = target;
      }
    });

    const occupanyData = properties.map((property) =>
      getPropertyOccupancyData(property)
    );

    return occupanyData;
  } catch (error) {
    console.log("parseOccupancyData/error: ", error);
    return null;
  }
};

export const parseRenewalsData = (data) => {
  try {
    // console.log('parseRenewalsData/data', data); // Debugging: Log the input data

    const renewalsData = data.renewals
      .map((item, index) => {
        // Check if propertyId exists and is valid
        if (!item.propertyId) {
          console.error(`Invalid propertyId at index ${index}: `, item);
          return null;
        }

        const total = item.ren_ntv_goal === "NULL" ? 0 : item.ren_ntv_goal;

        const propertyData = {
          propertyID: item.propertyId, // Correct field name
          title: "Renewals",
          barData: {
            value: Number(item.ren_ntv.toFixed(2)),
            total,
          },
          topData: {
            label: "Renewed",
            value: item.renewed || 0, // Default to 0 if undefined
          },
          bottomData: {
            label: "Notice to Vacate",
            value: item.ntv || 0, // Default to 0 if undefined
          },
        };

        return propertyData;
      })
      .filter((item) => item !== null); // Filter out any null values

    // console.log('renewalsData: ', renewalsData); // Debugging: Log the parsed renewals data

    const filteredRenewalsData = renewalsData.filter(
      (item) => item.propertyID.length > 4
    );

    return filteredRenewalsData;
  } catch (error) {
    console.log("parseRenewalsData/error: ", error);
    return null;
  }
};
export const parseTourConversionData = (data) => {
  try {
    // console.log('parseTourConversionData/data', data);

    const tourConversionData = data.tour_conversion.map((item) => {
      const propertyData = {
        propertyID: item.propertyid,
        title: "Tour Conversion",
        barData: {
          value: Number(item.leased_lost_ratio.toFixed(2)),
          total: item.goal_ratio === "NULL" ? 0 : item.goal_ratio,
        },
        topData: {
          label: "Leased",
          value: item.leased,
        },
        bottomData: {
          label: "Lost",
          value: item.lost,
        },
      };

      return propertyData;
    });
    // console.log('tourConversionData: ', tourConversionData);

    return tourConversionData;
  } catch (error) {
    console.log("parseTourConversionData/error: ", error);
    return null;
  }
};

export const parseTourData = (data) => {
  try {
    // console.log('parseTourData/data', data);

    const tourData = data.tours.map((item) => {
      const propertyData = {
        propertyID: item.PropertyID,
        title: "Tours",
        barData: {
          value: item.tour_actual,
          total: item.tour_goal,
        },
        topData: {
          label: "Projected Variance",
          value: item.projected_variance,
        },
        bottomData: {
          label: "Actual Tour Count",
          value: item.tour_actual,
        },
      };

      return propertyData;
    });
    // console.log('tourData: ', tourData);

    return tourData;
  } catch (error) {
    console.log("parseTourData/error: ", error);
    return null;
  }
};

export const parseGoalsData = (data) => {
  try {
    console.log("parseGoalsData/data", data);
    if (!data || !data.goals) {
      return null;
    }

    const goalsData = data.goals.map((item) => {
      const propertyData = {
        propertyID: item.PropertyID,
        ReportDate: item.ReportDate,
        ...item.goals,
      };

      return propertyData;
    });

    console.log("goalsData: ", goalsData);

    return goalsData;
  } catch (error) {
    console.log("parseGoalsData/error: ", error);
    return null;
  }
};

export const parseGoalsDisplayData = (data) => {
  try {
    console.log("parseGoalsDisplayData/data", data);

    return data;
  } catch (error) {
    console.log("parseGoalsDisplayData/error: ", error);
    return null;
  }
};

export const parseUpdateGoalsData = (data) => {
  try {
    console.log("parseUpdateGoalsData/data", data);

    return data;
  } catch (error) {
    console.log("parseUpdateGoalsData/error: ", error);
    return null;
  }
};

// @desc Delay timeout to simulate async action
// @param ms - delay in milliseconds
// @param consoleLogMsg - message to log to console
// @return Promise - value = true
export const delay = (ms, consoleLogMsg) =>
  new Promise((resolve) => {
    const message = consoleLogMsg ? consoleLogMsg : "Delay expired";
    setTimeout(() => {
      console.log(message);
      resolve(true);
    }, ms);
  });
