import { AxiosError, AxiosResponse } from "axios";
import { httpRequest } from "./request";

function getGPRDataService(token: string): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/gpr-data`, "get", token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getGPRGraphDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/gpr-graph`, "get", token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getLostLeasesDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/lost-leases`, "get", token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getOccupancyDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/occupancy`, "get", token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getRenewalDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/renewals`, "get", token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getTourConversionDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/tour-conversion`, "get", token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getTourDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/tours`, "get", token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getSubMarketChartData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("stats/sub-market-chart", "get", token, null, true, {
      propertyId,
      bedrooms,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getSubMarketMetricsData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("stats/market-metrics", "get", token, null, true, {
      propertyId,
      bedrooms,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getConcessionsTableData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("stats/market-concessions", "get", token, null, true, {
      propertyId,
      bedrooms,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}


function getCompIntensityProfileData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("stats/comp-intensity-profile", "get", token, null, true, {
      propertyId,
      bedrooms
    })
      .then((res: AxiosResponse) => { 
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}
function getRecentLeaseData(
  { propertyId, bedrooms }: { propertyId: string; bedrooms: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("stats/recent-leases", "get", token, null, true, {
      propertyId,
      bedrooms,
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}


function getPropertyRooms(
  { propertyId }: { propertyId: string },
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest("stats/bedrooms-by-property", "get", token, null, true, {
      propertyId
    })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getGoalsDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/goals`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function getGoalsDisplayDataService(
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/goals/display`, 'get', token, null, false)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

function updateGoalsDataService(
  body: any,
  token: string
): Promise<AxiosResponse | AxiosError> {
  return new Promise((resolve, reject) => {
    httpRequest(`stats/goals`, 'put', token, body)
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
}

export {
  getGPRDataService,
  getGPRGraphDataService,
  getLostLeasesDataService,
  getOccupancyDataService,
  getRenewalDataService,
  getTourConversionDataService,
  getTourDataService,
  getGoalsDataService,
  getGoalsDisplayDataService,
  updateGoalsDataService,
  getSubMarketChartData,
  getSubMarketMetricsData,
  getConcessionsTableData,
  getCompIntensityProfileData,
  getRecentLeaseData,
  getPropertyRooms
};
