import { useCallback, useEffect, useMemo } from 'react';

import OccupancyGPRChart from './OccupancyGPRChart';
import StatLoader from './StatLoader';

import { useAppDispatch, useAppSelector } from '../../store/hooks';

import {
  getGPRGraphData,
  getOccupancyStats
} from '../../store/stats/stats-slice';

import styles from './stats.module.scss';

interface componentProps {
  propertyID: string;
}

const Occupancy = ({ propertyID }: componentProps): ReactNode => {
  const dispatch = useAppDispatch();
  const occupancy: any = useAppSelector((state) => state.stats.occupancy);
  const gprGraph: any = useAppSelector((state) => state.stats.gprGraph);

  const selectedGPRGraphData = useMemo(() => {
    if (!gprGraph.isLoading && gprGraph.data.length === 0) {
      return;
    }

    if (!propertyID || gprGraph.isLoading) {
      return gprGraph.data[0];
    }

    const selectedData = gprGraph.data.find(
      (property: any) => property.propertyID === propertyID
    );

    return selectedData ? selectedData : gprGraph.data[0];
  }, [propertyID, gprGraph]);

  const selectedOccupancyData = useMemo(() => {
    if (!occupancy.isLoading && occupancy.data.length === 0) {
      return;
    }

    if (!propertyID || occupancy.isLoading) {
      return occupancy.data[0];
    }

    const selectedData = occupancy.data.find(
      (property: any) => property.propertyID === propertyID
    );

    return selectedData ? selectedData : occupancy.data[0];
  }, [propertyID, occupancy]);

  const fetchData = useCallback(async () => {
    try {
      dispatch(getOccupancyStats());
      dispatch(getGPRGraphData());
    } catch (error) {
      console.log('error:', error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.statWrapper}>
      <StatLoader isLoading={occupancy.isLoading || gprGraph.isLoading} />

      <div className={styles.statOccupancyCard}>
        <div className={styles.statTitleWrapper}>
          <span className={styles.statTitle}>Occupancy</span>
        </div>
        <OccupancyGPRChart
          data={{
            gprGraph: selectedGPRGraphData,
            occupancy: selectedOccupancyData
          }}
        />
      </div>
    </div>
  );
};

export default Occupancy;
