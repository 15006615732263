import { Col, Row, Select, Typography } from "antd";
import classes from "./styles.module.scss";
import { BarChart, Loader, StackedBarChart } from "../../../../components";
import { asyncGetUserProperties } from "../../../../store/renewal/renewal-async";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { TableArea } from "./table-area";
import { useEffect, useState } from "react";
import { getPricingSummary } from "../../../../services/income.services";
import toast from "react-hot-toast";

const Summary = () => {
  const [selectedBedroom, setSelectedBedroom] = useState<null | number>(1);
  const [propertyOptions, setPropertyOptions] = useState<Item[] | []>([]);
  const [pricingSummary, setPricingSummary] = useState([]);
  const [filter, setFilter] = useState("");
  const [loader, setLoader] = useState<boolean>(true);
  const [activeLabel, setActiveLabel] = useState<string>("");
  const { user, token }: { [key: string]: any } = useAppSelector((s) => s.user);
  const dispatch = useAppDispatch();

  const barHeight = 90;
  const data = [400, 430, 448, 470, 540, 580, 690, 1100, 1200];
  const labels = [
    "South Korea",
    "Canada",
    "United Kingdom",
    "Netherlands",
    "Italy",
    "France",
    "Japan",
    "United States",
    "China",
  ];
  const bedrooms = [1, 2, 3, 4, 0];

  const getUserProperties = async () => {
    try {
      const userProperies = await dispatch(
        asyncGetUserProperties(`${user?.idTokenClaims?.oid}`)
      ).unwrap();
      setPropertyOptions(
        userProperies.data.map((obj: any) => ({
          label: obj.property_name,
          value: obj.ms_property_id,
        }))
      );
      setFilter(userProperies.data[0].ms_property_id);
      setActiveLabel(userProperies.data[0].property_name);
    } catch (error) {}
  };

  const fetchPricingSummary = async (
    selectedPropertyId: string,
    selectedBedroomCount: number | null
  ) => {
    setLoader(true);
    try {
      const res: any = await getPricingSummary(
        {
          propertyId: selectedPropertyId ?? "",
          bedrooms: selectedBedroomCount ?? 1,
        },
        token
      );
      setPricingSummary(res.data || []);
      // setPricingSummary(
      //   Array.from({ length: 1 }).map(() => res?.data[0]) as any
      // );
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching data");
    }
  };

  useEffect(() => {
    if (user) {
      getUserProperties();
    }
  }, [user]);

  useEffect(() => {
    if (filter) {
      fetchPricingSummary(filter, selectedBedroom);
    }
  }, [filter, selectedBedroom]);

  if (loader) {
    return <Loader />;
  }

  return (
    <div>
      {/* <Typography.Title level={2} style={{ margin: 0, marginBottom: 8 }}>
        Pricing Summary
      </Typography.Title> */}
      <Row gutter={[20, 20]} align="stretch">
        <Col xs={24}>
          <div className={classes.container}>
            <div className={classes.wrapper}>
              <div className={classes.propertyWrapper}>
                <Typography.Text>Property</Typography.Text>
                <Select
                  placeholder="Property"
                  options={propertyOptions}
                  value={filter}
                  onChange={(val: string, option: any) => {
                    setFilter(val);
                    setActiveLabel(option.label);
                  }}
                  style={{ width: "100%", marginTop: "8px", color: "#8390A2" }}
                />
              </div>
              <div className={classes.bedroomContainer}>
                <Typography.Text style={{ margin: 0 }}>
                  Bedrooms
                </Typography.Text>
                <div className={classes.bedroomWrapper}>
                  {bedrooms.map((bedroom, ind) => (
                    <div
                      className={`${classes.bedrooms}  ${
                        bedroom === selectedBedroom && classes.selected
                      }`}
                      key={ind}
                      onClick={() => setSelectedBedroom(bedroom)}
                    >
                      {bedroom === 0 ? "Studio" : bedroom}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div className={classes.flexContainer}>
            <div className={`${classes.container} ${classes.flexItem}`}>
              <BarChart
                data={pricingSummary?.map((obj: any) => obj?.Mlv)}
                labels={pricingSummary?.map((obj: any) => obj?.property_name)}
                tooltip={false}
                height={barHeight * pricingSummary.length}
                title="Total Monthly Lease Value"
                YTitle="Property"
                XTitle="Amount"
                activeLabel={activeLabel}
              />
            </div>

            <div className={`${classes.container} ${classes.flexItem}`}>
              <TableArea
                data={pricingSummary.sort((a: any, b: any) =>
                  a.Mlv > b.Mlv ? -1 : 1
                )}
              />
            </div>
          </div>
        </Col>

        <Col xs={24}>
          <div className={classes.container}>
            <StackedBarChart
              data={pricingSummary}
              labels={pricingSummary?.map((obj: any) => obj?.property_name)}
              XTitle="Property"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Summary;
