import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createGoalsService,
  getForecastDataService,
  getGoalsService,
  getGoalsDataService,
  updateGoalsService
} from '../../services/goals.service';

import {
  parseForecastData,
  parseForecastReqBody,
  parseGoals,
  parseGoalsCreate,
  parseGoalsData,
  parseGoalsUpdate
} from './goals-utils';

export const getGoals = createAsyncThunk(
  'goals/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getGoalsService(
        propertyID,
        getState().user.token
      );
      // console.log('getGoalsData: ', result);

      const parsedData = parseGoals(result.data);
      // console.log('getGoalsData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const createGoals = createAsyncThunk(
  'goals/create',
  async (data: any, { dispatch, getState }: any) => {
    try {
      const body = parseGoalsCreate(data);

      const result: any = await createGoalsService(body, getState().user.token);
      console.log('createGoals/result: ', result);

      dispatch(getGoals(result.data?.PropertyID));

      return { success: result.status === 201 };
    } catch (error) {
      console.log('createGoals/error: ', error);
      return { success: false };
    }
  }
);

export const updateGoals = createAsyncThunk(
  'goals/update',
  async (data: any, { dispatch, getState }: any) => {
    try {
      const update = {
        pk_id: data.pk_id,
        manager_name: data.manager_name,
        goals: data.goals
      };
      const body = parseGoalsUpdate(update);

      const result: any = await updateGoalsService(body, getState().user.token);
      console.log('updateGoals/result: ', result);

      dispatch(getGoals(data.PropertyID));

      return { success: true };
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

export const getGoalsData = createAsyncThunk(
  'goals/data/get',
  async (propertyID: string, { getState }: any) => {
    try {
      const result: any = await getGoalsDataService(
        propertyID,
        getState().user.token
      );
      // console.log('getGoalsData: ', result);

      const parsedData = parseGoalsData(result.data);
      // console.log('getGoalsData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const getForecastData = createAsyncThunk(
  'goals/forecast/get',
  async (data: any, { getState }: any) => {
    try {
      const body = parseForecastReqBody(data);

      const result: any = await getForecastDataService(
        body,
        getState().user.token
      );
      // console.log('getForecastData/result: ', result);

      const parsedData = parseForecastData(result.data);
      // console.log('getForecastData/parsedData', parsedData);

      return parsedData as any;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
