import Chart from 'react-apexcharts';

import {
  purple300,
  grey700,
  indigo500,
  orange500,
  textColor
} from './chartColors';

import styles from './forecastChart.module.scss';

const defaultGraphData = new Array(12).fill(0);

const ForecastChart = ({ data }) => {
  const { graphData, occupancy } = data;

  const actualData = graphData?.actual ? graphData.actual : defaultGraphData;
  const budgetData = graphData?.budget ? graphData.budget : defaultGraphData;
  const forecastData = graphData?.forecast
    ? graphData.forecast
    : defaultGraphData;

  const occupancyData = occupancy
    ? occupancy.data.map((month) => month.value)
    : [];

  // console.log('actualData: ', actualData);
  // console.log('budgetData: ', budgetData);
  // console.log('forecastData: ', forecastData);
  // console.log('occupancyData: ', occupancyData);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const maxLevel = Math.round(
    Math.max(...actualData, ...budgetData, ...forecastData) * 1.1
  );
  // console.log('maxLevel: ', maxLevel);

  const chartHeight = 420;

  const chartSeries = [
    {
      name: 'Budget',
      type: 'column',
      data: budgetData
    },
    {
      name: 'Actual',
      type: 'column',
      data: actualData
    },
    {
      name: 'Forecast',
      type: 'column',
      data: forecastData
    },
    {
      name: 'Occupancy',
      type: 'line',
      data: occupancyData
    }
  ];

  const chartOptions = {
    chart: {
      background: '#fff',
      height: chartHeight,
      width: '100%',
      type: 'line',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: 'end',
        columnWidth: '80%'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: [8, 8, 8, 6],
      // colors: [indigo500, orange500, purple300]
      colors: ['transparent', 'transparent', 'transparent', purple300]
    },
    xaxis: {
      type: 'category',
      categories: months,
      labels: {
        show: true,
        style: {
          colors: grey700,
          fontFamily: 'Poppins, sans-serif',
          fontSize: '14px',
          fontWeight: 400
        }
      },
      tooltip: {
        enabled: false
      }
    },

    yaxis: [
      {
        show: true,
        seriesName: 'Budget',
        title: {
          text: 'ERR',
          style: {
            color: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '18px',
            fontWeight: '400'
          }
        },
        labels: {
          formatter: (val) => (val ? `${val.toFixed(1)}K` : ''),
          style: {
            colors: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '11px',
            fontWeight: 400
          }
        },
        max: maxLevel || 100
      },
      {
        show: false,
        seriesName: 'Actual',
        max: maxLevel || 100
      },
      {
        show: false,
        seriesName: 'Forecast',
        max: maxLevel || 100
      },
      {
        show: true,
        seriesName: 'Occupancy',
        title: {
          text: 'Occupancy',
          style: {
            color: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '18px',
            fontWeight: '400'
          }
        },
        labels: {
          formatter: (val) => (val ? `${Math.round(val)}%` : ''),
          style: {
            colors: grey700,
            fontFamily: 'Poppins, sans-serif',
            fontSize: '11px',
            fontWeight: 400
          }
        },
        opposite: true,
        min: 0,
        max: 100
      }
    ],
    markers: {
      size: 6,
      strokeWidth: 0,
      fillOpacity: 0,
      hover: {
        size: 8
      }
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const seriesData = series[seriesIndex];
        const dataPoint = seriesData[dataPointIndex];
        // console.log('seriesData: ', seriesData);
        // console.log('dataPoint: ', dataPoint);

        if (dataPoint === 0) {
          return null;
        }
        const dataType =
          seriesIndex === 0
            ? 'budget'
            : seriesIndex === 1
            ? 'actual'
            : seriesIndex === 2
            ? 'forecast'
            : 'occupancy';
        // console.log('dataType: ', dataType);

        const formattedDataPoint =
          seriesIndex === 3 ? `${dataPoint}%` : `${dataPoint}K`;
        // console.log('formattedDataPoint: ', formattedDataPoint);
        return `
           <div class='${styles.chartTooltip}' data-type='${dataType}'>
            <div>${dataType}</div> 
            <div>${formattedDataPoint}</div> 
          </div>
          `;
      }
    },
    legend: {
      show: true,
      labels: {
        colors: [textColor, textColor, textColor, textColor]
      }
    },
    colors: [indigo500, orange500, grey700, purple300]
  };

  return (
    <div id='chart-wrapper-forecast' style={{ width: '100%' }}>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type={'line'}
        height={chartHeight}
      />
    </div>
  );
};
export default ForecastChart;
