export const sumOfArrays = (arrays: number[][] = [[]]) => {
  const sums = new Array(arrays[0].length).fill(0);

  // Iterate through each array and calculate the sum
  arrays.forEach((array) => {
    array.forEach((num, index) => {
      sums[index] += num;
    });
  });

  return sums;
};
