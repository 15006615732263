/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Grid, Row, Select, Typography } from "antd";
import classes from "./styles.module.scss";
import { BarChart, Loader } from "../../../../components";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useEffect, useState } from "react";
import { asyncGetUserProperties } from "../../../../store/renewal/renewal-async";
import { getMovInFees } from "../../../../services/income.services";
import { sumOfArrays } from "../../../../utils/common";
import toast from "react-hot-toast";

const { useBreakpoint } = Grid;
const MoveInFees = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    adminFee: [],
    apartmentDeposit: [],
    petDeposit: [],
    applicationFee: [],
    sums: [],
    sumsLabels: [],
  });
  const [propertyOptions, setPropertyOptions] = useState<Item[] | []>([]);
  const [filter, setFilter] = useState("");
  const [activeLabel, setActiveLabel] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(true);
  const { lg } = useBreakpoint();

  const { user, token }: { [key: string]: any } = useAppSelector((s) => s.user);
  const barHeight = 90;
  const barHeightGroup = 60;
  const dispatch = useAppDispatch();

  const getUserProperties = async () => {
    try {
      const userProperies = await dispatch(
        asyncGetUserProperties(`${user?.idTokenClaims?.oid}`)
      ).unwrap();
      setPropertyOptions(
        userProperies.data.map((obj: any) => ({
          label: obj.property_name,
          value: obj.ms_property_id,
        }))
      );

      setFilter(userProperies.data[0].ms_property_id);
      setActiveLabel(userProperies.data[0].property_name);
    } catch (error) {}
  };

  const fetchMoveInFee = async (selectedPropertyId: string) => {
    setLoader(true);
    try {
      const res: any = await getMovInFees(
        {
          propertyId: selectedPropertyId ?? "",
        },
        token
      );
      setLoader(false);

      const labels = res.data.map((item: any) => item.property_name || "");
      const adminFee = res.data.map((item: any) => item.AdminFee || 0);
      const apartmentDeposit = res.data.map(
        (item: any) => item.ApartmentDeposit || 0
      );
      const petDeposit = res.data.map((item: any) => item.PetDepositFee || 0);
      const applicationFee = res.data.map(
        (item: any) => item.ApplicationFee || 0
      );

      const arrays = [
        [...adminFee],
        [...apartmentDeposit],
        [...petDeposit],
        [...applicationFee],
      ];
      const sums = sumOfArrays(arrays);
      const sumsLabels: string[] = [];
      const data = labels.map((label: string, index: number) => ({
        label,
        sum: sums[index],
      }));
      data.sort((a: { sum: number }, b: { sum: number }) => b.sum - a.sum);
      data.forEach((item: { label: any; sum: any }, index: number) => {
        sumsLabels[index] = item.label;
        sums[index] = item.sum;
      });

      setChartData({
        labels,
        adminFee,
        apartmentDeposit,
        petDeposit,
        applicationFee,
        sums: sums as any,
        sumsLabels: sumsLabels as any,
      });
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching data");
    }
  };

  useEffect(() => {
    if (user) {
      getUserProperties();
    }
  }, [user]);

  useEffect(() => {
    if (filter) {
      fetchMoveInFee(filter);
    }
  }, [filter]);

  if (loader) {
    return <Loader />;
  }

  return (
    <div>
      {/* <Typography.Title level={2} style={{ margin: 0, marginBottom: 8 }}>
        Move-in Fees
      </Typography.Title> */}
      <Row gutter={[20, 20]}>
        <Col xs={24}>
          <div className={classes.container}>
            <div className={classes.flex}>
              <Typography.Text style={{ margin: 0 }}>Property</Typography.Text>
              <Select
                placeholder="Property"
                options={propertyOptions}
                value={filter}
                onChange={(val: string, option: any) => {
                  setActiveLabel(option.label);
                  setFilter(val);
                }}
                style={{ width: "200px" }}
              />
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div className={classes.container}>
            <BarChart
              data={chartData.sums}
              labels={chartData.sumsLabels}
              title="Total Move-In Fees"
              height={barHeight * chartData.sums.length}
              tooltip={false}
              activeLabel={activeLabel}
              YTitle="Property"
              XTitle="Amount"
              group={false}
            />
          </div>
        </Col>
        <Col xs={24}>
          <div className={classes.container}>
            <span className={classes.yLabel}>Property</span>
            <span className={classes.xLabel}>Amount</span>
            <Typography.Title level={5} style={{ margin: 0, marginBottom: 4 }}>
              Move-In Fees by Fee Type
            </Typography.Title>

            <div style={{ padding: "50px 0" }}>
              <div className={classes.chartRowContainer}>
                <div className={`${classes.chartColumn} `}>
                  <BarChart
                    data={chartData.adminFee}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.sums.length}
                    title="Admin Fee"
                    xAxisLabels={false}
                    tooltip={false}
                    activeLabel={activeLabel}
                    group={true}
                  />
                </div>
                <div className={`${classes.chartColumn} ${classes.borderLeft}`}>
                  <BarChart
                    data={chartData.apartmentDeposit}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.sums.length}
                    title="Apartment Deposit"
                    yAxisLabels={!lg}
                    xAxisLabels={false}
                    tooltip={false}
                    activeLabel={activeLabel}
                    group={true}
                  />
                </div>
              </div>
              <div className={classes.borderBottom} />
              <div className={classes.chartRowContainer}>
                <div className={`${classes.chartColumn} ${classes.lastRow}`}>
                  <BarChart
                    data={chartData.petDeposit}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.sums.length}
                    title="Pet Deposit"
                    tooltip={false}
                    activeLabel={activeLabel}
                    xAxisLabels={false}
                    group={true}
                  />
                </div>
                <div
                  className={`${classes.chartColumn} ${classes.borderLeft} ${classes.lastRow}`}
                >
                  <BarChart
                    data={chartData.applicationFee}
                    labels={chartData.labels}
                    height={barHeightGroup * chartData.sums.length}
                    yAxisLabels={!lg}
                    title="Application Fee"
                    tooltip={false}
                    activeLabel={activeLabel}
                    xAxisLabels={false}
                    group={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MoveInFees;
