/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setProperty } from "../../../store/properties/property-slice";
import InputForm from "./inputForm";
import Summary from "./summary";
import MoveInFees from "./move-in-fees";
import MonthlyFees from "./monthly-fees";

const IncomePlus = () => {
  const dispatch = useAppDispatch();
  const {
    property: { activeTab },
  } = useAppSelector((s) => ({ property: s.property, user: s.user }));

  useEffect(() => {
    dispatch(
      setProperty({
        tabs: ["Input Form", "Pricing Summary", "Move-In Fees", "Monthly Fees"],
      })
    );
    return () => {
      dispatch(setProperty({ tabs: [], activeTab: 0 }));
    };
  }, []);

  return (
    <>
      {activeTab === 0 && <InputForm />}
      {activeTab === 1 && <Summary />}
      {activeTab === 2 && <MoveInFees />}
      {activeTab === 3 && <MonthlyFees />}
    </>
  );
};

export default IncomePlus;
