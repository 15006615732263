import {
  monthKeys,
  convertMonthKeyValuePairs,
  getMonthIdWithMonthNum
} from '../../utils/months';

export const initialMonthKeyValuePairs = {
  jan: 0,
  feb: 0,
  mar: 0,
  apr: 0,
  may: 0,
  jun: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  oct: 0,
  nov: 0,
  dec: 0
};

export const getGoalsInitialData = () => ({
  propertyID: '',
  goals: {
    concessions: initialMonthKeyValuePairs,
    netLeasesPct: 0,
    renewalPct: 0,
    tours: initialMonthKeyValuePairs,
    tourConversionPct: 0
  }
});

export const convertNumArrayToStr = (array) => {
  try {
    const serializedStr = array.map((item) => item.toString()).join(',');
    // console.log('serializedStr: ', serializedStr);

    return serializedStr;
  } catch (error) {
    console.log('convertStrToNumArray/error: ', error);
    return null;
  }
};

export const convertStrToNumArray = (str) => {
  try {
    const numArray = str.split(',').map((item) => Number(item));
    // console.log('numArray: ', numArray);

    return numArray;
  } catch (error) {
    console.log('convertStrToNumArray/error: ', error);
    return null;
  }
};

export const parseGoals = (data) => {
  try {
    // console.log('parseGoals/data', data);

    if (!data || !data.goals || !data.goals.length) {
      return null;
    }
    const goals = parseTargetString(data.goals[0].goals);
    const propertyGoals = {
      pk_id: data.goals[0].pk_id,
      PropertyID: data.goals[0].PropertyID,
      goals
    };

    // console.log('propertyGoals: ', propertyGoals);

    return propertyGoals;
  } catch (error) {
    console.log('parseGoals/error: ', error);
    return null;
  }
};

export const parseGoalsCreate = (data) => {
  try {
    // console.log('parseCreateGoals/data', data);

    return data;
  } catch (error) {
    console.log('parseCreateGoals/error: ', error);
    return null;
  }
};

export const parseGoalsUpdate = (data) => {
  try {
    // console.log('parseUpdateGoals/data', data);

    return data;
  } catch (error) {
    console.log('parseUpdateGoals/error: ', error);
    return null;
  }
};
export const parseGoalsData = (data) => {
  try {
    // console.log('parseGoalsData/data', data);
    const { gmr, lossToLease, vacancyLoss, concessionLoss } = data;

    let effRentRev = {};
    let gpr = {};

    for (const month in gmr) {
      const gprVal = gmr[month] + lossToLease[month];
      gpr[month] = gprVal;

      const errSubtotal = gprVal + vacancyLoss[month];
      const concessionLossVal = (concessionLoss && concessionLoss[month]) || 0;
      const effRentRevMonth = errSubtotal + concessionLossVal;
      effRentRev[month] = effRentRevMonth;
    }

    const parsed = {
      ...data,
      gpr,
      effRentRev
    };

    return parsed;
  } catch (error) {
    console.log('parseGoalsData/error: ', error);
    return null;
  }
};
export const parseForecastReqBody = (data) => {
  try {
    // console.log('parseForecastReqBody/data', data);

    const property_id = data.propertyID;
    const concession_weeks_free = convertMonthKeyValuePairs(data.concessions);
    const conversion_rate = data.tourConversionPct / 100;
    const net_leases_percent = data.netLeasesPct / 100;
    const renewal_rate = data.renewalPct / 100;
    const tours = convertMonthKeyValuePairs(data.tours);

    const body = {
      property_id,
      concession_weeks_free,
      conversion_rate,
      net_leases_percent,
      renewal_rate,
      tours
    };
    // console.log('body: ', body);

    return body;
  } catch (error) {
    console.log('parseForecastReqBody/error: ', error);
    return null;
  }
};
export const parseForecastData = (data) => {
  try {
    // console.log('parseForecastData/data', data);
    let forecast = {};

    const monthObjs = Object.values(data)[0];

    for (const [month, fields] of Object.entries(monthObjs)) {
      const monthId = getMonthIdWithMonthNum(month);

      forecast.concessionLoss = forecast.concessionLoss || {};
      forecast.concessionLoss[monthId] = Math.round(fields.concessionLoss);

      forecast.effRentRev = forecast.effRentRev || {};
      forecast.effRentRev[monthId] = Math.round(fields.effectiveRentRevenue);

      forecast.gmr = forecast.gmr || {};
      forecast.gmr[monthId] = Math.round(fields.grossMarketRent);

      forecast.gpr = forecast.gpr || {};
      forecast.gpr[monthId] = Math.round(fields.grossPotentialRent);

      forecast.lossToLease = forecast.lossToLease || {};
      forecast.lossToLease[monthId] = Math.round(fields.totalLossToLease);

      forecast.vacancyLoss = forecast.vacancyLoss || {};
      forecast.vacancyLoss[monthId] = Math.round(fields.totalVacancyLoss);
    }

    // console.log('forecast: ', forecast);

    return forecast;
  } catch (error) {
    console.log('parseForecastData/error: ', error);
    return null;
  }
};

function parseTargetString(targetString) {
  // Remove backslashes
  const cleaned = targetString.replace(/\\/g, '');

  // Parse into object
  const parsed = JSON.parse(cleaned);
  // console.log('PARSED STRING: ', parsed);

  return parsed;
}

export const getApplicationsForecast = (data) => {
  try {
    const { tourGoals, tourConversionPctGoal } = data;

    let applicationsForecast = {};
    if (!tourConversionPctGoal || !tourGoals) {
      return initialMonthKeyValuePairs;
    }

    for (const month in tourGoals) {
      const monthApps = Math.round(
        tourGoals[month] * (tourConversionPctGoal / 100)
      );

      applicationsForecast[month] = monthApps;
    }
    // console.log('applicationsForecast: ', applicationsForecast);
    return applicationsForecast;
  } catch (error) {
    console.log('getApplicationsForecast/error: ', error);
    return {};
  }
};

export const getMoveInsForecast = (data) => {
  try {
    const { netLeasesPctGoal, tourGoals, tourConversionPctGoal } = data;

    let moveInsForecast = {};

    for (const month in tourGoals) {
      if (netLeasesPctGoal === 0 || tourConversionPctGoal === 0) {
        moveInsForecast[month] = 0;
        continue;
      }
      const monthApps = Math.round(
        tourGoals[month] * (tourConversionPctGoal / 100)
      );
      const monthMoveIns = Math.round(monthApps * (netLeasesPctGoal / 100));
      moveInsForecast[month] = monthMoveIns;
    }
    // console.log('moveInsForecast: ', moveInsForecast);
    return moveInsForecast;
  } catch (error) {
    console.log('getMoveInsForecast/error: ', error);
    return {};
  }
};

export const getMoveOutsForecast = (data) => {
  try {
    const { expiringLeases, renewalPctGoal } = data;

    let moveOutsForecast = {};

    for (const month in expiringLeases) {
      if (renewalPctGoal === 0) {
        moveOutsForecast[month] = expiringLeases[month];
        continue;
      }
      const monthMoveOuts = Math.round(
        expiringLeases[month] * (1 - renewalPctGoal / 100)
      );
      moveOutsForecast[month] = monthMoveOuts;
    }
    // console.log('moveOutsForecast: ', moveOutsForecast);
    return moveOutsForecast;
  } catch (error) {
    console.log('getMoveOutsForecast/error: ', error);
    return {};
  }
};

export const getGraphData = (data) => {
  try {
    // console.log('getGraphData/data', data);
    const { effRentRev, errBudget, errForecast } = data;

    const actual = monthKeys.reduce((acc, curr) => {
      const value = effRentRev[curr] || 0;
      // console.log('value: ', value);
      const actualVal = Number((value / 1000).toFixed(1));

      // console.log('actualVal: ', actualVal);
      acc.push(actualVal);
      return acc;
    }, []);

    const budget = monthKeys.reduce((acc, curr) => {
      const value = errBudget[curr] || 0;
      // console.log('value: ', value);
      const budgetVal = Number((value / 1000).toFixed(1));

      // console.log('budgetVal: ', budgetVal);
      acc.push(budgetVal);
      return acc;
    }, []);

    const forecast = monthKeys.reduce((acc, curr) => {
      const value = errForecast[curr] || 0;
      const forecastVal = Number((value / 1000).toFixed(1));

      // console.log('forecastVal: ', forecastVal);
      acc.push(forecastVal);
      return acc;
    }, []);

    const graphData = {
      actual,
      budget,
      forecast
    };
    // console.log('graphData: ', graphData);

    return graphData;
  } catch (error) {
    console.log('getGraphData/error: ', error);
    return {
      actual: [],
      forecast: []
    };
  }
};
