/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { DatePicker, InputNumber, Select, Table } from "antd";
import { AiOutlineDownload } from "react-icons/ai";
import { IoMdSend } from "react-icons/io";

import { GrPowerReset } from "react-icons/gr";
import Styles from "./style.module.scss";
import AppButton from "../../../components/button";
import { useEffect, useState } from "react";
import {
  asyncGetRenewalData,
  asyncGetUserProperties,
  asyncGetUserRoles,
  asyncUpdateRenewalData,
  asyncSendRenewalLetter,
} from "../../../store/renewal/renewal-async";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { RangePickerProps } from "antd/es/date-picker";
import dayjs, { Dayjs } from "dayjs";
import StandardModal from "./modals/standardModal";
import MtmModal from "./modals/mtmModal";
import ConfirmModal from "./modals/confirmationModal";
import jsPDF from "jspdf";
import pdfLogo from "../../../assets/images/pdfLogo.jpg";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import moment from "moment";
import { IoSaveOutline } from "react-icons/io5";
import { setRenewal } from "../../../store/renewal/renewal-slice";
import { Modal } from "antd";
import classes from "./style.module.scss";
import { Loader } from "../../../components";

const bandedRowClassName = (_: any, index: number) => {
  return index % 2 === 0 ? `${Styles.evenRows} editable-row` : `editable-row`;
};
type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const renewalTypeOptions = [
  { label: "Standard", value: "standard" },
  { label: "MTM", value: "month-to-month" },
];

const btnStyleObj = { display: "flex", alignItems: "center" };
const btnIconStyleObj = {
  marginRight: "10px",
  cursor: "pointer",
  fontSize: 20,
};

const Renewal = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number | undefined | any>(1);
  const [pageSize, setPageSize] = useState<number | undefined | any>(100);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [data, setData] = useState<IObject[]>();
  const [confirm, setConfirm] = useState<Boolean>(false);
  const [propertySelectedValue, setPropertySelectedValue] = useState<
    string | null
  >(urlParams.get("property"));
  const [propertyOptions, setPropertyOptions] = useState<
    { [key: string]: any }[]
  >([]);
  const [typeSelectedValue, setTypeSelectedValue] = useState<string | null>(
    urlParams.get("type") || "standard"
  );
  const [leaseEndMonthSelectedValue, setLeaseEndMonthSelectedValue] =
    useState<Dayjs | null>(
      urlParams.get("date") ? dayjs(urlParams.get("date")) : null
    );
  const [standardModalOpen, setStandardModalOpen] = useState(false);
  const [mtmModalOpen, setMtmodalMOpen] = useState(false);
  const [standardRecord, setStandardRecord] = useState<IObject | null>(null);
  const [mtmRecord, setMtmRecord] = useState<IObject | null>(null);
  const [selectedRows, setSelectedRows] = useState<IObject[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [renewalDataUpdatedValues, setRenewalDataUpdatedValues] = useState<
    IObject[]
  >([]);
  const [tableScrollY, setTableScrollY] = useState(300);
  const dispatch = useAppDispatch();
  const { user }: { [key: string]: any } = useAppSelector((s) => s.user);
  const [isManager, setIsManager] = useState(false);
  const [isRpm, setIsRpm] = useState(false);
  const [isVp, setIsVp] = useState(false);

  const updateTableHeight = () => {
    const newHeight = window.innerHeight - 295; // Subtract 295px from the viewport height
    setTableScrollY(newHeight);
  };

  const handleDataChange = (value: any, record: any, key: string) => {
    const mappedData = data?.map((row) =>
      row?.pk_id === record?.pk_id ? { ...row, [key]: value } : row
    );
    setData(mappedData);
    let copiedArr = [...renewalDataUpdatedValues];
    let filteredRecord = copiedArr.find((r) => r.pk_id === record?.pk_id);
    if (filteredRecord) {
      if (value == null) {
        delete filteredRecord[key];
        if (Object.keys(filteredRecord).length === 1) {
          copiedArr = copiedArr?.filter((r) => r?.pk_id !== record?.pk_id);
        }
      } else {
        filteredRecord[key] = value;
      }
      setRenewalDataUpdatedValues(copiedArr);
    } else {
      setRenewalDataUpdatedValues((prev) => [
        ...prev,
        { pk_id: record?.pk_id, [key]: value || 0 },
      ]);
    }
  };

  const showOneDayAfterLeaseEndDate = (date: IObject) => {
    const givenDate = moment(date).utc();
    return givenDate.add(1, "day").format("MM/DD/YYYY");
  };

  const getFirstDate = (data: string) => {
    const dateVal = moment(data);
    return dateVal.date() <= 6
      ? dateVal.add(1, "months").startOf("month").format("MM/DD/YYYY")
      : dateVal.add(2, "months").startOf("month").format("MM/DD/YYYY");
  };

  useEffect(() => {
    fetchRenewalPlusData();
  }, [page]);

  useEffect(() => {
    updateTableHeight();
    window.addEventListener("resize", updateTableHeight);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateTableHeight);
  }, []);

  useEffect(() => {
    const url = new URL(`${window.location}`);
    if (leaseEndMonthSelectedValue)
      url.searchParams.set("date", leaseEndMonthSelectedValue.toString());
    if (typeSelectedValue) url.searchParams.set("type", typeSelectedValue);
    if (propertySelectedValue)
      url.searchParams.set("property", propertySelectedValue);

    if (!leaseEndMonthSelectedValue) url.searchParams.delete("date");
    if (!typeSelectedValue) url.searchParams.delete("type");
    if (!propertySelectedValue) url.searchParams.delete("property");

    window.history.replaceState(null, "", url);
  }, [leaseEndMonthSelectedValue, propertySelectedValue, typeSelectedValue]);

  const { renewalUnsavedModal }: any = useAppSelector((state) => state.renewal);
  const { roles }: any = useAppSelector((state) => state.user);

  const defaultColumns: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "Unit",
      dataIndex: "Unitnumber",
      key: "Unitnumber",
      width: 70,
      fixed: "left",
    },

    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "renewal_letter_status",
      key: "renewal_letter_status",
      render: (text, record: any) => (
        <p
          style={{
            background:
              record?.renewal_letter_status?.toLowerCase() === "sent"
                ? "#a0dea0"
                : record?.renewal_letter_status?.toLowerCase() === "pending"
                ? "yellow"
                : "transparent",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: -16,
            left: 0,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{text}</span>
        </p>
      ),
      width: 90,
    },
    {
      title: "Property",
      dataIndex: "property_name",
      key: "property_name",
      width: 150,
    },
    {
      title: "Unit Type",
      dataIndex: "UnitTypeName",
      key: "UnitTypeName",
      width: 150,
    },
    {
      title: "Lease End Date",
      dataIndex: "LeaseEndDate",
      key: "LeaseEndDate",
      render: (date) => (
        <p>{moment(date.split("T")[0]).format("MM/DD/YYYY")}</p>
      ),
      width: 150,
    },
    {
      title: "Base Rent",
      dataIndex: "base_rent",
      key: "base_rent",
      render: (rent) => <p>{new Intl.NumberFormat("en-IN").format(rent)}</p>,
      width: 100,
    },
    {
      title: "Market Rent",
      dataIndex: "market_rent",
      key: "market_rent",
      render: (rent) => <p>{new Intl.NumberFormat("en-IN").format(rent)}</p>,
      width: 100,
    },
    {
      title: "Current Fee Total",
      dataIndex: "current_fee_total",
      key: "current_fee_total",
      render: (current_fee_total) => (
        <p>{new Intl.NumberFormat("en-IN").format(current_fee_total)}</p>
      ),
      width: 140,
    },
    {
      title: "Minimum Renewal Rate",
      dataIndex: "minimum_renewal",
      key: "minimum_renewal",
      render: (minimum_renewal) => (
        <p>
          {new Intl.NumberFormat("en-IN").format(Math.round(minimum_renewal))}
        </p>
      ),
      width: 150,
    },
    {
      title: "Target Rate",
      dataIndex: "target_rate",
      key: "target_rate",
      render: (target_rate) => (
        <p>{new Intl.NumberFormat("en-IN").format(target_rate)}</p>
      ),
      width: 100,
    },
    {
      title: "Manager Rate",
      dataIndex: "manager_rate",
      key: "manager_rate",
      render: (_: any, record: any) => (
        <InputNumber
          status={
            renewalDataUpdatedValues?.find((r) => r.pk_id === record?.pk_id)
              ?.manager_rate <= 0
              ? "error"
              : ""
          }
          style={{ width: "100%" }}
          value={record.manager_rate}
          onChange={(value: any) =>
            handleDataChange(value, record, "manager_rate")
          }
          disabled={
            !roles?.find(
              (role: any) => role?.role_name === "ico_property_manager"
            )
          }
        />
      ),
      width: 110,
    },
    {
      title: "RPM Rate",
      dataIndex: "rpm_rate",
      key: "rpm_rate",
      render: (_, record: any) => (
        <InputNumber
          status={
            renewalDataUpdatedValues?.find((r) => r.pk_id === record?.pk_id)
              ?.rpm_rate <= 0
              ? "error"
              : ""
          }
          style={{ width: "100%" }}
          value={record.rpm_rate}
          onChange={(value: any) => handleDataChange(value, record, "rpm_rate")}
          disabled={
            !roles?.find((role: any) => role?.role_name === "ico_regional")
          }
        />
      ),
      width: 110,
    },
    {
      title: "VP Rate",
      dataIndex: "vp_rate",
      key: "vp_rate",
      render: (_, record: any) => (
        <InputNumber
          status={
            renewalDataUpdatedValues?.find((r) => r.pk_id === record?.pk_id)
              ?.vp_rate <= 0
              ? "error"
              : ""
          }
          style={{ width: "100%" }}
          value={record.vp_rate}
          onChange={(value: any) => handleDataChange(value, record, "vp_rate")}
          disabled={!roles?.find((role: any) => role?.role_name === "ico_vp")}
        />
      ),
      width: 110,
    },
    {
      title: "Standard Fee Total",
      dataIndex: "standard_fee_total",
      key: "standard_fee_total",
      render: (_, record: any) => (
        <p
          onClick={() => handleStandardModalClick(record)}
          style={{
            color: "blue",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {new Intl.NumberFormat("en-IN").format(record.standard_fee_total)}
        </p>
      ),
      width: 140,
    },
    {
      title: "MTM Fee Total",
      dataIndex: "mtm_fee_total",
      key: "mtm_fee_total",
      render: (_, record: any) => (
        <p
          onClick={() => handleMTMModalClick(record)}
          style={{
            color: "blue",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {new Intl.NumberFormat("en-IN").format(record.mtm_fee_total)}
        </p>
      ),
      width: 110,
    },
    {
      title: "Standard Total",
      dataIndex: "standard_total",
      key: "standard_total",
      width: 110,
      render: (standard_total) => (
        <p>{new Intl.NumberFormat("en-IN").format(standard_total)}</p>
      ),
    },
    {
      title: "MTM Total",
      dataIndex: "mtm_total",
      key: "mtm_total",
      width: 90,
      render: (mtm_total) => (
        <p>{new Intl.NumberFormat("en-IN").format(mtm_total)}</p>
      ),
    },

    {
      title: "Requested Increase",
      dataIndex: "requested_increase",
      key: "requested_increase",
      width: 110,
      render: (_: any, record: any) => {
        const requestedIncrease =
          record.standard_total - (record.current_fee_total + record.base_rent);

        return (
          <p>{new Intl.NumberFormat("en-IN").format(requestedIncrease)}</p>
        );
      },
    },
  ];

  const handleDataSave = async () => {
    try {
      setLoading(true);
      const pendingPromises = renewalDataUpdatedValues?.map((r) =>
        dispatch(asyncUpdateRenewalData(r))
      );
      await Promise.all(pendingPromises);
      await fetchData();
      setRenewalDataUpdatedValues([]);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleStandardModalClick = (record: { [key: string]: any }) => {
    setStandardRecord(record);
    setStandardModalOpen(true);
  };

  const handleMTMModalClick = (record: { [key: string]: any }) => {
    setMtmRecord(record);
    setMtmodalMOpen(true);
  };

  const columns: any = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record: { [key: string]: any }) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  const fetchRenewalPlusData = async (reset = false) => {
    try {
      setLoading(true);

      const secondPromise = dispatch(
        asyncGetUserProperties(user?.idTokenClaims?.oid)
      ).unwrap();
      const thirdPromise = dispatch(
        asyncGetUserRoles(user?.localAccountId)
      ).unwrap();
      const [result2, result3]: any = await Promise.all([
        secondPromise,
        thirdPromise,
      ]);

      commonHandler(result3, result2);
      if (result2?.data?.length) {
        const result = await dispatch(
          asyncGetRenewalData({
            page,
            pageSize,
            oid: user?.idTokenClaims?.oid,
            LeaseType: reset ? "standard" : typeSelectedValue,
            property_name: reset ? null : propertySelectedValue,
            LeaseEndDate: reset ? null : leaseEndMonthSelectedValue,
          })
        ).unwrap();
        setData(result?.data);
        setTotalCount(result?.data?.length ? result?.data[0]!.totalCount : 0);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await dispatch(
        asyncGetRenewalData({
          page,
          pageSize,
          oid: user?.idTokenClaims?.oid,
          LeaseType: typeSelectedValue,
          property_name: propertySelectedValue,
          LeaseEndDate: leaseEndMonthSelectedValue,
        })
      ).unwrap();

      setLoading(false);
      setData(result.data);
      setTotalCount(result.data[0].totalCount);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleResetClick = async () => {
    await fetchRenewalPlusData(true);
    setPropertySelectedValue(null);
    setTypeSelectedValue("standard");
    setLeaseEndMonthSelectedValue(null);
    const url = new URL(`${window.location}`);
    url.searchParams.delete("date");
    url.searchParams.delete("type");
    url.searchParams.delete("property");
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    const threeMonthsAgo = dayjs().subtract(3, "months").startOf("month");
    return current && current < threeMonthsAgo;
  };

  const handleFilterTypes = (
    queryStart: string,
    queryEnd: string,
    value: string,
    returnValue: string | null | any
  ) => {
    if (queryStart === queryEnd) return value;
    else return returnValue;
  };

  const handleOnChange = async (
    value: string | any,
    setState: (value: string | any) => void | any,
    type: string
  ) => {
    try {
      setState(value);
      setLoading(true);
      setPage(1);
      setPageSize(pageSize);
      setRenewalDataUpdatedValues([]);
      const result = await dispatch(
        asyncGetRenewalData({
          page: 1,
          pageSize,
          oid: user?.idTokenClaims?.oid,
          LeaseType: handleFilterTypes(
            type,
            "LeaseType",
            value,
            typeSelectedValue
          ),
          property_name: handleFilterTypes(
            type,
            "property_name",
            value,
            propertySelectedValue
          ),
          LeaseEndDate: handleFilterTypes(
            type,
            "LeaseEndDate",
            value,
            leaseEndMonthSelectedValue
          ),
        })
      );
      setData(result.payload.data);
      setTotalCount(
        result?.payload?.data?.length ? result.payload.data[0].totalCount : 0
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[], selectedRows: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  const sendRenewalData = async () => {
    try {
      const requestData = selectedRows
        ?.filter(
          ({ renewal_letter_status }: any) =>
            renewal_letter_status?.toLowerCase() === "not sent"
        )
        .map((row) => {
          const {
            property_name,
            street_address,
            Name,
            current_total,
            manager_name,
            manager_email,
            city,
            state,
            zip,
            LeaseEndDate,
            standard_total,
            mtm_total,
            pk_id,
          } = row;
          const mail_from = user.username;
          return {
            id: pk_id,
            property_name,
            street_address,
            city,
            state,
            zip,
            Name,
            LeaseEndDate,
            current_total,
            standard_total,
            mtm_total,
            manager_name,
            manager_email,
            mail_from,
          };
        });
      if (requestData?.length) {
        const finalPayload = {
          payload: requestData,
          type:
            typeSelectedValue === "standard" ? "standard" : "month-to-month",
        };

        setConfirm(false);
        setLoading(true);
        await dispatch(asyncSendRenewalLetter(finalPayload));
        await fetchData();
        setLoading(false);
        setSelectedRows([]);
        setSelectedRowKeys([]);
      } else {
        setConfirm(false);
        setSelectedRows([]);
        setSelectedRowKeys([]);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const confirmHandler = (value: Boolean) => setConfirm(value);

  const handlePdf = () => {
    const updatedSelectedRows = updateSelectedRowsWithLatestData();

    const zip = new JSZip();
    const pdfPromises: IObject[] = [];
    updatedSelectedRows?.forEach((element) => {
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
      });

      pdf.addImage(pdfLogo, "PNG", 400, 30, 140, 20);
      pdf.output("datauri");

      let template: string | HTMLElement | any = null;

      // element.street_address=null;

      if (typeSelectedValue === "month-to-month") {
        template = ` <main
        style="
          width: 1500px;
          height: 600px;
          font-size: 11px;
          font-family: Helvetica;
        "
      >
        <p style="margin-top: 1rem">${moment().utc().format("MM/DD/YYYY")}</p>
        <div style="margin:1rem 0;">
        ${
          element?.property_name
            ? `<p style="margin: 0;">${element?.property_name}</p>`
            : ""
        }
        ${`<p style="margin: 0;">${element.street_address ?? ""} ${
          element?.Unitnumber
        }</p>`}
        <p style="margin: 0;">${element.city ? `${element?.city}, ` : ""}${
          element.state ? `${element.state} ` : ""
        }${element?.zip ? `${element.zip}` : ""}</p>
        </div>

        <p style="margin-bottom: 1.5rem">Dear ${element.Name},</p>

        <p style="line-height: 1.4;">
          <b>Your current lease rate is: $${new Intl.NumberFormat(
            "en-IN"
          ).format(element.current_total)}.00.</b> <br />
          This total includes your Internet, Cable, Parking and your pet(s),
          storages, etc. if you added them.
          </p>
          <p style="margin-bottom:1.5rem;"><b
          >We have several options available for you if you are interested in
          renewing your lease <br />
          at a great rate!</b
        ></p> 

        <p style="line-height: 1.4">
          If you would prefer to continue Month-to-Month, your new rate will be
          <b>$${new Intl.NumberFormat("en-IN").format(
            element.mtm_total
          )}.00</b> beginning <br />
          <b>${getFirstDate(new Date().toISOString().split("T")[0])}.</b>
        </p>

        <p>
          If you are planning to move out, a written 60-day Notice to Vacate is required.
        </p>

        <p style="line-height: 1.4">
          If you have any questions please call, text, or email us. We sincerely
          thank you for choosing to call <br />
          ${element.property_name} your home. We look forward to continuing
          to serve you.
        </p>

        <p style="margin-bottom: 0.4rem; margin-top: 1rem">Sincerely,</p>
        <p style="margin: 0">${element.manager_name}</p>
        <p style="margin: 0">Community Manager</p>
        ${
          element?.phone_number
            ? `<p style="margin: 0">(${element?.phone_number.slice(
                0,
                3
              )})${element?.phone_number.slice(
                3,
                6
              )}-${element?.phone_number.slice(6)}</p>`
            : ""
        }
        ${
          element?.manager_email
            ? `<p style="margin: 0;">${element?.manager_email}</p>`
            : ""
        }
      </main>`;
      } else {
        template = `
        <main style="width:1500px;height:600px;font-size:11px;font-family: Helvetica;">
          <p style="margin-top:1rem;" >${moment()
            .utc()
            .format("MM/DD/YYYY")}</p>
          <div style="margin:1rem 0;">
            ${
              element?.property_name
                ? `<p style="margin: 0;">${element?.property_name}</p>`
                : ""
            }
            ${`<p style="margin: 0;">${element.street_address ?? ""} ${
              element?.Unitnumber
            }</p>`}
            
            <p style="margin: 0;">${element.city ? `${element?.city}, ` : ""}${
          element.state ? `${element.state} ` : ""
        }${element?.zip ? `${element.zip}` : ""}</p>
          </div>


          <p style="margin-bottom: 1.5rem;" >Dear ${element.Name},</p>

          <p style="line-height: 1.4;margin-bottom: 1.5rem;">
            We hope you are enjoying living at ${element.property_name}. We
            want to let you know that <br/> your lease is set to expire on
            <b> ${moment(element.LeaseEndDate)
              .utc()
              .format(
                "MMMM DD, YYYY"
              )}</b> and we would love for you to renew your <br/> lease
            with us.
          </p>

          <p style="line-height: 1.4;margin-bottom: 1.5rem;">
            <b>Your current lease rate is: $${new Intl.NumberFormat(
              "en-IN"
            ).format(
              element.current_total
            )}.00 .</b> <br/> This total includes your
            Internet, Cable, Parking and your pet(s), storages, etc. if you added
            them.
          </p>

          <p style="line-height: 1.4;margin-bottom: 1.5rem;">
            <b>Your renewal rate will be: $${
              element.standard_total
            }.00 . </b><br/> (This price is for a 6-18
            month lease renewal based on availability).<br/> Please provide a copy of
            your updated renter's insurance. Simply call, email, or text us and we
            will <br/> email your renewal lease to you to sign.
          </p>

          <p style="line-height: 1.4;">
            If you would prefer to go Month-to-Month, or if all lease holders do not
            come in and sign the <br/> renewal lease prior to the lease expiration date,
            your rent will automatically increase to the Month- <br/>to-Month rate for a
            total of <b> $${new Intl.NumberFormat("en-IN").format(
              element.mtm_total
            )}.00</b> beginning <b> ${showOneDayAfterLeaseEndDate(
          element.LeaseEndDate
        )}.</b>
          </p>

          <p>
            If you are planning to move out, a written 60-day Notice to Vacate is
            required
          </p>

          <p style="line-height: 1.4;">
            If you have any questions please call, text, or email us. We sincerely
            thank you for choosing to call <br/> ${
              element.property_name
            } your home.
            We look forward to continuing to serve you.
          </p>

          <p style="margin-bottom: .4rem;margin-top: 2rem;">Sincerely,</p>
          <p style="margin: 0;">${element.manager_name}</p>
          <p style="margin: 0;">Community Manager</p>
          ${
            element?.phone_number
              ? `<p style="margin: 0">(${element?.phone_number.slice(
                  0,
                  3
                )})${element?.phone_number.slice(
                  3,
                  6
                )}-${element?.phone_number.slice(6)}</p>`
              : ""
          }
          ${
            element?.manager_email
              ? `<p style="margin: 0;">${element?.manager_email}</p>`
              : ""
          }
        </main>

        `;
      }
      const formattedDate = moment().format("MM-DD-YYYY");

      const pdfPromise = new Promise<void>((resolve) => {
        pdf.html(template, {
          callback: async function (doc) {
            const pdfName = `${element.Unitnumber}_renewal_${formattedDate}.pdf`;
            if (updatedSelectedRows.length === 1) doc.save(pdfName);
            else {
              await zip.file(pdfName, doc.output("blob"));
            }
            resolve();
          },
          x: 40,
          y: 40,
        });
      });
      if (updatedSelectedRows.length > 1) pdfPromises.push(pdfPromise);
    });

    if (pdfPromises.length) {
      setLoading(true);
      Promise.all(pdfPromises)
        .then(() => {
          setLoading(false);
          zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, "output.zip");
          });
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error generating PDFs or creating the zip:", error);
        });
    }
  };

  const handleOk = () => {
    dispatch(
      setRenewal({
        renewalUnsavedData: {},
        renewalUnsavedModal: false,
      })
    );
    setRenewalDataUpdatedValues([]);
  };

  const handleCancel = () => {
    dispatch(setRenewal({ renewalUnsavedModal: false }));
  };

  const commonHandler = (result3: any, result2: any) => {
    result3.data.recordset.forEach((elem: IObject) => {
      if (elem.role_name === "ico_manager") setIsManager(true);
      if (elem.role_name === "ico_rpm") setIsRpm(true);
      if (elem.role_name === "ico_vp") setIsVp(true);
      else {
        setIsManager(false);
        setIsRpm(false);
        setIsVp(false);
      }
    });

    setPropertyOptions(
      result2.data.map((obj: any) => ({
        label: obj.property_name,
        value: obj.property_name,
      }))
    );
  };

  const updateSelectedRowsWithLatestData = () => {
    if (!data) return selectedRows;
    const updatedSelectedRows = selectedRows.map((selectedRow) => {
      const latestDataRow = data.find(
        (dataRow) => dataRow.pk_id === selectedRow.pk_id
      );
      return latestDataRow ? { ...selectedRow, ...latestDataRow } : selectedRow;
    });

    setSelectedRows(updatedSelectedRows);
    return updatedSelectedRows;
  };

  const tableLoading = {
    spinning: loading,
    indicator: <Loader />,
  };

  return (
    <div>
      <div className={Styles.btnsContainer}>
        <Select
          placeholder="Please choose a property"
          value={propertySelectedValue}
          options={propertyOptions}
          onChange={(e: string) =>
            handleOnChange(e, setPropertySelectedValue, "property_name")
          }
          disabled={loading}
          className={Styles.select}
        />
        <Select
          placeholder="Type"
          onChange={(e) => handleOnChange(e, setTypeSelectedValue, "LeaseType")}
          disabled={loading}
          options={renewalTypeOptions}
          value={typeSelectedValue}
          className={Styles.select}
        />

        <DatePicker
          picker="month"
          format="MMMM YYYY"
          value={leaseEndMonthSelectedValue}
          allowClear={false}
          onChange={(e) =>
            handleOnChange(e, setLeaseEndMonthSelectedValue, "LeaseEndDate")
          }
          disabled={loading}
          disabledDate={disabledDate}
          placeholder="Please choose lease End Month"
          className={Styles.datePicker}
        />

        <AppButton
          isDisabled={loading}
          value="Reset"
          btnType="dashed"
          btnStyle={btnStyleObj}
          IconComponent={GrPowerReset}
          IconStyle={btnIconStyleObj}
          isIcon={true}
          onClick={handleResetClick}
        />
        <AppButton
          //
          isDisabled={
            !renewalDataUpdatedValues?.length ||
            renewalDataUpdatedValues?.some((r) =>
              Object.keys(r).some((key) => r[key] <= 0)
            ) ||
            loading
          }
          value="Save"
          btnType="dashed"
          btnStyle={{ display: "flex", alignItems: "center", gap: "6px" }}
          IconComponent={IoSaveOutline}
          IconStyle={{ fontSize: 17 }}
          isIcon={true}
          onClick={handleDataSave}
        />
        <AppButton
          isDisabled={!selectedRows.length || loading}
          value="Generate Renewal Letter"
          btnType="dashed"
          btnStyle={btnStyleObj}
          IconComponent={AiOutlineDownload}
          IconStyle={btnIconStyleObj}
          isIcon={true}
          onClick={handlePdf}
        />
        <AppButton
          isDisabled={!selectedRows.length || loading}
          value="Send Renewal"
          btnType="dashed"
          btnStyle={btnStyleObj}
          IconComponent={IoMdSend}
          IconStyle={btnIconStyleObj}
          isIcon={true}
          onClick={() => confirmHandler(true)}
        />
      </div>

      <div className={classes.container}>
        <Table
          rowSelection={{ type: "checkbox", ...rowSelection }}
          columns={columns as ColumnTypes}
          rowKey={(record: any) => record.pk_id}
          pagination={{
            total: totalCount,
            showSizeChanger: true,
            defaultCurrent: page,
            defaultPageSize: pageSize,
          }}
          onChange={(pagination) => {
            setPage(pagination.current);
            setPageSize(pagination.pageSize);
          }}
          loading={tableLoading}
          dataSource={data}
          sticky={{ offsetHeader: 0 }}
          scroll={{ x: 2110, y: tableScrollY }}
          rowClassName={bandedRowClassName}
        />
      </div>
      <ConfirmModal
        open={confirm}
        send={sendRenewalData}
        cancel={confirmHandler}
      />
      <StandardModal
        open={standardModalOpen}
        setOpen={setStandardModalOpen}
        record={standardRecord}
        fetchData={fetchData}
      />
      <MtmModal
        open={mtmModalOpen}
        setOpen={setMtmodalMOpen}
        record={mtmRecord}
        fetchData={fetchData}
      />

      <Modal open={renewalUnsavedModal} onOk={handleOk} onCancel={handleCancel}>
        <p>
          You have unsaved changes. Would you like to save or discard these
          changes?
        </p>
      </Modal>
    </div>
  );
};

export default Renewal;
