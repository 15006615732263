import { useCallback, useEffect, useMemo } from 'react';

import StatCard from '../../components/stats/StatCard';
import StatLoader from './StatLoader';

import { useAppDispatch, useAppSelector } from '../../store/hooks';

import { getTourConversionStats } from '../../store/stats/stats-slice';

interface TourConversionProps {
  propertyID: string;
}

const defaultData = {
  title: 'Tour Conversion',
  barData: {
    value: 0,
    total: 0
  },
  topData: {
    label: 'Leased',
    value: 0
  },
  bottomData: {
    label: 'Lost',
    value: 0
  }
};

const TourConversion = ({ propertyID }: TourConversionProps): ReactNode => {
  const dispatch = useAppDispatch();
  const tourConversion: any = useAppSelector(
    (state) => state.stats.tourConversion
  );
  // console.log('propertyID:', propertyID);

  const selectedPropertyData = useMemo(() => {
    if (tourConversion.isLoading || tourConversion.data.length === 0) {
      return defaultData;
    }

    if (
      !propertyID &&
      !tourConversion.isLoading &&
      tourConversion.data.length > 0
    ) {
      return defaultData;
    }

    const selectedData = tourConversion.data.find(
      (property: any) => property.propertyID === propertyID
    );

    return selectedData ? selectedData : tourConversion.data[0];
  }, [propertyID, tourConversion]);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(getTourConversionStats());
    } catch (error) {
      console.log('error:', error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <StatLoader isLoading={tourConversion.isLoading} />
      <StatCard
        data={selectedPropertyData ? selectedPropertyData : defaultData}
      />
    </>
  );
};

export default TourConversion;
