import { combineReducers, configureStore } from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage';

import commonReducer from './common/common-slice';
import goalsReducer from './goals/goals-slice';
import pricingReducer from './pricing/pricing-slice';
import propertyReducer from './properties/property-slice';
import renewalReducer from './renewal/renewal-slice';
import statsReducer from './stats/stats-slice';
import subscriptionsReducer from './subscriptions/subscription-slice';
import userReducer from './user/user-slice';

// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER
// } from 'redux-persist';

const allreducers = combineReducers({
  common: commonReducer,
  goals: goalsReducer,
  property: propertyReducer,
  renewal: renewalReducer,
  stats: statsReducer,
  subscriptions: subscriptionsReducer,
  user: userReducer,
  pricing: pricingReducer
});

// const persistConfig = {
//     key: "root",
//     version: 1,
//     storage,
//     whitelist: ['user'],
// };

// const rootReducer = (state: any, action: any) => {
//     if (action.type === 'Auth/logout') {
//         console.log("Auth/logout Called!")
//         return allreducers({...state, user: {}}, action);
//     }

//     return allreducers(state, action)
// }

// const persistedReducer = persistReducer(persistConfig, allreducers);

export const store = configureStore({
  reducer: allreducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },\
      serializableCheck: false
    })
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// export const persistor = persistStore(store);
