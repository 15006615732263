import { useCallback, useEffect, useMemo } from "react";

import { ConfigProvider, Tabs } from "antd";
import type { TabsProps } from "antd";

import GPRCard from "./GPRCard";
import StatLoader from "./StatLoader";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { getGPRStats } from "../../store/stats/stats-slice";

import styles from "./stats.module.scss";

interface componentProps {
  propertyID: string;
}

const GPR = ({ propertyID }: componentProps): ReactNode => {
  const dispatch = useAppDispatch();
  const gpr: any = useAppSelector((state) => state.stats.gpr);

  const selectedPropertyData = useMemo(() => {
    if (!gpr.isLoading && gpr.data.length === 0) {
      return {
        annualized: {
          budget: 0,
          actual: 0,
          variance: 0,
        },
        ytd: {
          budget: 0,
          actual: 0,
          variance: 0,
        },
      };
    }

    if (!propertyID || gpr.isLoading) {
      return gpr.data[0];
    }

    const selectedData = gpr.data.find(
      (property: any) => property.propertyID === propertyID
    );

    return selectedData ? selectedData : gpr.data[0];
  }, [propertyID, gpr]);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(getGPRStats());
    } catch (error) {
      console.log("error:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Year to Date",
      children: selectedPropertyData ? (
        <GPRCard data={selectedPropertyData.ytd} />
      ) : null,
    },
    {
      key: "2",
      label: "Annualized",
      children: selectedPropertyData ? (
        <GPRCard data={selectedPropertyData.annualized} />
      ) : null,
    },
  ];

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Poppins'
        },
        components: {
          Tabs: {
            cardGutter: 1,
            cardPaddingSM: '4px 6px',
            fontFamily: 'Poppins',
            fontSize: 12,
            itemActiveColor: '#2A313A',
            itemColor: '#8a8e93',
            itemHoverColor: '#8a8e93',
            itemSelectedColor: '#2A313A'
          }
        }
      }}
    >
      <div className={styles.statWrapper}>
        <StatLoader isLoading={gpr.isLoading} />
        <div className={styles.statGprCard}>
          <div className={styles.statGprCardTitle}>EGR</div>
          <Tabs
            defaultActiveKey={'1'}
            size={'small'}
            items={tabItems}
            centered
            type='card'
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default GPR;
