import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./sidebar.module.scss";
import sidebarLogo from "../../assets/images/sidebarLogo.svg";
import { useMsal } from "@azure/msal-react";
import admin from "../../assets/images/admin.svg";
import goalsIcon from "../../assets/images/income.svg";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import prospect from "../../assets/images/prospect.svg";
import { logout } from "../../store/user/user-slice";
import { IoLogOutOutline } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { setRenewal } from "../../store/renewal/renewal-slice";

const Sidebar: React.FC = () => {
  const router = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [show, setShow] = useState(false);
  const { userProducts, user, roles, userProperty }: any = useAppSelector(
    (state) => state.user
  );
  const { renewalUnsavedData }: any = useAppSelector((state) => state.renewal);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleToggleSidebar = () => setIsOpen((isOpen) => !isOpen);

  const handleClick = async () => {
    await dispatch(logout());
    await instance.logoutRedirect();
  };

  const handleSidebarLinksClick = async (param: string) => {
    if (Object.keys(renewalUnsavedData).length > 0) {
      dispatch(setRenewal({ renewalUnsavedModal: true }));
    } else {
      navigate(param);
    }
  };

  const renewalIcon = userProducts?.filter(
    ({ product }: any) => product === "Renewal+"
  )[0].iconURL;

  return (
    <div className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}>
      <button
        className={`${isOpen ? styles.close : styles.hamburger}`}
        onClick={handleToggleSidebar}
      >
        <span className={styles.hamburgerBox}>
          <span className={styles.hamburgerInner}></span>
        </span>
      </button>

      <div className={styles.sidebar_content}>
        <div className={styles.logo}>
          <img src={sidebarLogo} alt="" />
        </div>

        <div className={styles.sidebarMenu} style={{ marginTop: "2rem" }}>
          <ul className={styles.menu}>
            <li
              onClick={() => handleSidebarLinksClick("/home")}
              className={`${styles.menuItem} ${
                router.pathname === "/home" && styles.activeItem
              }`}
            >
              <img className={styles.homeImg} src={prospect} alt="" />{" "}
              <p>Home</p>
              <div className={styles.menuHover} />
            </li>

            <li
              onClick={() => handleSidebarLinksClick("/goals")}
              className={`${styles.menuItem} ${
                router.pathname === "/goals" && styles.activeItem
              }`}
            >
              <img className={styles.homeImg} src={goalsIcon} alt="" />{" "}
              <p>Goals</p>
              <div className={styles.menuHover} />
            </li>

            {userProducts && !!userProducts.length
              ? userProducts
                  ?.filter(({ product }: any) => product !== "Renewal+")
                  ?.map(
                    (
                      item: { product: string; iconURL: string },
                      index: number
                    ) => (
                      <li
                        onClick={() =>
                          handleSidebarLinksClick(`/products/${item.product}`)
                        }
                        key={index}
                        className={`${styles.menuItem} ${
                          router.pathname === `/products/${item.product}` &&
                          styles.activeItem
                        }`}
                      >
                        <img
                          className={
                            index === 0 ? styles.prospectImg : styles.webImg
                          }
                          src={item.iconURL}
                          alt=""
                        />{" "}
                        {item.product}
                        <div className={styles.menuHover} />
                      </li>
                    )
                  )
              : ""}
          </ul>

          <ul className={styles.menu}>
            {roles &&
              roles?.find(
                (record: any) => record.role_name === "lux_admin"
              ) && (
                <li
                  onClick={() => handleSidebarLinksClick("/admin")}
                  className={`${styles.menuItem} ${
                    router.pathname === "/admin" && styles.activeItem
                  }`}
                >
                  <img className={styles.admin} src={admin} alt="" />
                  <p>Administration</p>
                  <div className={styles.menuHover} />
                </li>
              )}
            {userProperty && userProperty?.length > 0 && (
              <li
                onClick={() => handleSidebarLinksClick("/renewal")}
                className={`${styles.menuItem} ${
                  router.pathname === "/renewal" && styles.activeItem
                }`}
              >
                <img className={styles.admin} src={renewalIcon} alt="" />
                <p>Renewal+</p>
                <div className={styles.menuHover} />
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className={styles.user}>
        <div className={styles.divider}></div>
        <div className={styles.main} onClick={() => setShow(!show)}>
          <div>
            <div className={styles.avatarMain}>
              <div>
                <p>{user?.name?.slice(0, 1)}</p>
              </div>
            </div>
            <div className={styles.namesMain}>
              <h6>{user?.name}</h6>
            </div>
          </div>
          <div className={styles.dropArrowIcon}>
            <p>
              {" "}
              <IoMdArrowDropdown />{" "}
            </p>
          </div>

          {show && (
            <div
              onMouseLeave={() => setShow(false)}
              className={styles.popup_main}
            >
              {/* <p>
                {" "}
                <IoSettingsOutline /> <span>Profile</span>{" "}
              </p> */}
              <p onClick={handleClick}>
                {" "}
                <IoLogOutOutline />
                <span>Logout</span>{" "}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
