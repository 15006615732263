import { useCallback, useEffect, useMemo } from 'react';
import { Spin } from 'antd';

import ForecastChart from './ForecastChart';

import { useAppDispatch, useAppSelector } from '../../store/hooks';

import {
  getGPRGraphData,
  getOccupancyStats
} from '../../store/stats/stats-slice';
import { getGraphData } from '../../store/goals/goals-utils';

import styles from './forecastChart.module.scss';

interface componentProps {
  propertyID: string;
}

const Forecast = ({ propertyID }: componentProps): ReactNode => {
  const dispatch = useAppDispatch();
  const occupancy: any = useAppSelector((state) => state.stats.occupancy);
  const { effRentRev, errBudget }: any = useAppSelector(
    (state) => state.goals.propertyData
  );
  const errForecast: any = useAppSelector(
    (state) => state.goals.forecastData.effRentRev
  );
  const isForecastDataLoading = useAppSelector(
    (state) => state.goals.isForecastDataLoading
  );
  // console.log('Forecast => occupancy:', occupancy);
  const isLoading = isForecastDataLoading || occupancy.isLoading;

  const graphData = useMemo(() => {
    const data = getGraphData({ effRentRev, errBudget, errForecast });
    // console.log('Forecast => graphData:', data);

    return data;
  }, [effRentRev, errBudget, errForecast]);
  // console.log('Forecast => graphData:', graphData);

  const selectedOccupancyData = useMemo(() => {
    if (!occupancy.isLoading && occupancy.data.length === 0) {
      return;
    }

    if (!propertyID || occupancy.isLoading) {
      return occupancy.data[0];
    }

    const selectedData = occupancy.data.find(
      (property: any) => property.propertyID === propertyID
    );

    return selectedData ? selectedData : occupancy.data[0];
  }, [propertyID, occupancy]);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(getOccupancyStats());
      await dispatch(getGPRGraphData());
    } catch (error) {
      console.log('error:', error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className={styles.forecastWrapper}>
        <div className={styles.forecastContainer}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>Forecast</span>
            {isLoading && <Spin />}
          </div>
          <ForecastChart
            data={{
              graphData,
              occupancy: selectedOccupancyData
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Forecast;
