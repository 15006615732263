import { Checkbox, Col, Row, Select, TableProps, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Loader, ScatterPlot, SubMCard } from "../../../../components";
import { asyncGetUserProperties } from "../../../../store/renewal/renewal-async";
import classes from "./styles.module.scss";
import unitsIcon from "../../../../assets/images/Icons/units-icon.png";
import box from "../../../../assets/images/Icons/box.svg";
import dollar from "../../../../assets/images/Icons/dollar.png";
import floorIcon from "../../../../assets/images/Icons/floorplan-icon.png";
import { TableArea } from "./table-area";
import toast from "react-hot-toast";
import {
  getConcessionsTableData,
  getSubMarketChartData,
  getSubMarketMetricsData,
  getCompIntensityProfileData,
  getRecentLeaseData,
  getPropertyRooms,
} from "../../../../services/stats.service";
import moment from "moment";
import SubICard from "../../../../components/stats-card";

const commonProfileDataObj = {
  oneMonth: 0,
  oneWeek: 0,
  threeMonths: 0,
  avgDayToLease: 0,
};

function getRandomHexColor() {
  const randomColor = Math.floor(Math.random() * 16777215);
  const hexColor = `#${randomColor.toString(16).padStart(6, "0")}`;
  return hexColor;
}

const generateUniqueHexColors = (size: number): string[] => {
  var customColors = [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#00FFFF",
    "#FF00FF",
    "#FFA500",
    "#800080",
    "#00FF00",
    "#FFC0CB",
    "#008080",
    "#E6E6FA",
    "#A52A2A",
    "#800000",
    "#808000",
    "#000080",
    "#FFD700",
    "#FF7F50",
    "#40E0D0",
    "#C0C0C0",
    "#4B0082",
    "#EE82EE",
    "#F0E68C",
    "#DC143C",
    "#FA8072",
  ];
  const colorSet = new Set(customColors);

  while (colorSet.size < size) {
    colorSet.add(getRandomHexColor());
  }

  return Array.from(colorSet) as string[];
};

function DailyPricing(): ReactNode {
  const columns: TableProps<any>["columns"] = [
    {
      title: () => <span style={{ fontWeight: 400 }}>Property</span>,
      dataIndex: "property_name",
      key: "property_name",
      width: "30%",
      sorter: (a, b) => a.property_name.localeCompare(b.property_name),
      ellipsis: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: <span style={{ fontWeight: 400 }}>Advertised Concession</span>,
      dataIndex: "concessions",
      key: "concessions",
    },
  ];

  const columnsCompTable: TableProps<any>["columns"] = [
    {
      title: <span style={{ fontWeight: 400 }}>Property</span>,
      dataIndex: "property_name",
      key: "property_name",
      sorter: (a, b) => a.property_name.localeCompare(b.property_name),
      ellipsis: true,
      sortDirections: ["descend", "ascend"],
    },

    {
      title: <span style={{ fontWeight: 400 }}>Unit</span>,
      dataIndex: "unit",
      key: "unit",
      sorter: (a, b) => a.unit.localeCompare(b.unit),
      ellipsis: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: <span style={{ fontWeight: 400 }}>Rate</span>,
      dataIndex: "rate",
      key: "rate",
      render: (_, record) => {
        return `$${record.rate.toLocaleString()}`;
      },
    },
    {
      title: <span style={{ fontWeight: 400 }}>Size</span>,
      dataIndex: "size",
      key: "size",
      render: (_, record) => {
        return `${record.size.toLocaleString()} sqft`;
      },
    },
    {
      title: <span style={{ fontWeight: 400 }}>Lease Date</span>,
      dataIndex: "lease_date",
      render: (_, record) => {
        return `${moment(record.lease_date?.split("T")[0]).format(
          "MM/DD/YYYY"
        )}`;
      },
      key: "lease_date",
      sorter: (a, b) => a.lease_date.localeCompare(b.lease_date),
    },
  ];
  const columnsYouTable: TableProps<any>["columns"] = [
    {
      title: <span style={{ fontWeight: 400 }}>Unit</span>,
      dataIndex: "unit",
      key: "unit",
      sorter: (a, b) => a.unit.localeCompare(b.unit),
      ellipsis: true,
      sortDirections: ["descend", "ascend"],
    },
    {
      title: <span style={{ fontWeight: 400 }}>Rate</span>,
      dataIndex: "rate",
      key: "rate",
      render: (_, record) => {
        return `$${record.rate.toLocaleString()}`;
      },
    },
    {
      title: <span style={{ fontWeight: 400 }}>Size</span>,
      dataIndex: "size",
      key: "size",
      render: (_, record) => {
        return `${record.size.toLocaleString()} sqft`;
      },
    },
    {
      title: <span style={{ fontWeight: 400 }}>Lease Date</span>,
      dataIndex: "lease_date",
      render: (_, record) => {
        return `${moment(record.lease_date?.split("T")[0]).format(
          "MM/DD/YYYY"
        )}`;
      },
      key: "lease_date",
      sorter: (a, b) => a.lease_date.localeCompare(b.lease_date),
    },
  ];
  const [loader, setLoader] = useState<boolean>(true);
  const [propertyOptions, setPropertyOptions] = useState<Item[] | []>([]);
  const renderRef = useRef<boolean>(true);
  const [bedroomOptions, setBedroomOptions] = useState<Item[] | []>([
    {
      label: "All",
      value: "",
    },
  ]);
  const [data, setData] = useState<{
    filteredChartData: ChartDataItem[];
    uniqueProperties: string[];
    selectedUniqueProperty: string;
    key: number;
    colors: string[];
    legendColors: string[];
    showSelectedProperty: boolean;
    selectedPropertyId: string;
    propertyOptions: Item[];
  }>({
    filteredChartData: [],
    selectedUniqueProperty: "",
    uniqueProperties: [],
    key: 0,
    colors: [],
    legendColors: [],
    showSelectedProperty: false,
    selectedPropertyId: "",
    propertyOptions: [],
  });
  const [chartData, setChartData] = useState<ChartDataItem[] | []>([]);
  const [chartColors, setChartColors] = useState<string[]>([]);
  const [cardsData, setCardsData] = useState<MetricsData[] | []>([]);
  const [concessionsData, setConcessionsData] = useState([]);
  const [youProfileData, setYouProfileData] = useState<ProfileData>({
    profile: "You",
    ...commonProfileDataObj,
  });
  const [compProfileData, setCompProfileData] = useState<ProfileData>({
    profile: "Competitors",
    ...commonProfileDataObj,
  });
  const [youRecentData, setYouRecentData] = useState<RecentLeaseData[]>([]);
  const [compRecentData, setCompRecentData] = useState<RecentLeaseData[]>([]);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectedProperty, setselectedProperty] = useState("");
  const [legendColors, setLegendColors] = useState<string[]>([]);
  const { user, token }: { [key: string]: any } = useAppSelector((s) => s.user);
  const dispatch = useAppDispatch();

  const getUserProperties = async () => {
    try {
      const userProperies = await dispatch(
        asyncGetUserProperties(`${user?.idTokenClaims?.oid}`)
      ).unwrap();
      setPropertyOptions(
        userProperies.data.map((obj: any) => ({
          label: obj.property_name,
          value: obj.ms_property_id,
        }))
      );

      if (userProperies.data.length) {
        const roomProperties: any = await getPropertyRooms(
          { propertyId: userProperies["data"][0]["ms_property_id"] },
          token
        );
        setBedroomOptions([
          {
            label: "All",
            value: "",
          },
          ...roomProperties.data.bedroom.map((record: any) => ({
            label:
              record.bedrooms <= 1
                ? `${record.bedrooms} Bedroom`
                : `${record.bedrooms} Bedrooms`,
            value: `${record.bedrooms}`,
          })),
        ]);
      }
      setLoader(false);
      setselectedProperty(userProperies.data[0]["ms_property_id"]);
    } catch (error) {}
  };

  const fetchChartData = async (
    selectedPropertyId: string,
    selectedBedroomCount: string | null
  ) => {
    try {
      const res: any = await getSubMarketChartData(
        {
          propertyId: selectedPropertyId ?? "",
          bedrooms: selectedBedroomCount ?? "",
        },
        token
      );

      const data =
        res.data.chart_data?.map((item: any) => ({
          x: item.unit_size,
          y: item.market_rent,
          property: item.property,
          floorplan_name: item.floorplan_name,
          unit_number: item.unit_number,
        })) || [];

      // @ts-ignore
      const properties = [...new Set(data.map((record) => record?.property))];
      setChartData(data);
      const colors: string[] = generateUniqueHexColors(properties.length);
      setChartColors(colors);

      // @ts-ignore
      setData((prev: any) => ({
        ...prev,
        filteredChartData: data || [],
        uniqueProperties: properties,
        selectedUniqueProperty: "",
        key: Math.random(),
        colors: colors,
        legendColors: colors,
        selectedPropertyId: selectedProperty,
        propertyOptions,
      }));
      setLegendColors(colors);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching chart data");
    }
  };

  const fetchCardsData = async (
    selectedPropertyId: string,
    selectedBedroomCount: string | null
  ) => {
    const metricDetails: { [key: string]: { color: string; icon: string } } = {
      "units listed": { color: "#4361f9", icon: unitsIcon },
      "average rate": { color: "#A15FFB", icon: dollar },
      "average size": { color: "#FFB84C", icon: floorIcon },
      "days on market": { color: "#A15FFB", icon: box },
    };
    try {
      const res: any = await getSubMarketMetricsData(
        {
          propertyId: selectedPropertyId ?? "",
          bedrooms: selectedBedroomCount ?? "",
        },
        token
      );

      var data: InputData[] = res.data.metrics_data;

      const result = data.reduce<MetricsData[]>(
        (acc, { Category, MetricName, Value }) => {
          const { color, icon } = metricDetails[MetricName] || {
            color: "#4361f9",
            icon: unitsIcon,
          };

          let metric = acc.find((item) => item.MetricName === MetricName);
          if (!metric) {
            metric = { MetricName, Metrics: [], color, icon };
            acc.push(metric);
          }

          metric.Metrics.push({ Category, Value });
          return acc;
        },
        []
      );

      setCardsData(result || []);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching card data");
    }
  };

  const fetchTableData = async (
    selectedPropertyId: string,
    selectedBedroomCount: string | null
  ) => {
    try {
      const res: any = await getConcessionsTableData(
        {
          propertyId: selectedPropertyId ?? "",
          bedrooms: selectedBedroomCount ?? "",
        },
        token
      );

      setConcessionsData(
        res.data.concessions_data?.map((record: any) => ({
          ...record,
          concessions: record?.concessions || "None",
        }))
      );

      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching table data");
    }
  };

  const fetchRecentLeaseData = async (
    selectedPropertyId: string,
    selectedBedroomCount: string | null
  ) => {
    try {
      const res: any = await getRecentLeaseData(
        {
          propertyId: selectedPropertyId ?? "",
          bedrooms: selectedBedroomCount ?? "",
        },
        token
      );
      setYouRecentData(res.data.you_recent_leases);
      setCompRecentData(res.data.comp_recent_leases);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching recent lease data");
    }
  };

  const fetchCompIntensityProfileData = async (
    selectedPropertyId: string,
    selectedBedroomCount: string | null
  ) => {
    try {
      setYouProfileData({
        profile: "You",
        ...commonProfileDataObj,
      });
      setCompProfileData({
        profile: "Competitors",
        ...commonProfileDataObj,
      });

      const res: any = await getCompIntensityProfileData(
        {
          propertyId: selectedPropertyId ?? "",
          bedrooms: selectedBedroomCount ?? "",
        },
        token
      );

      const data: ProfileData[] = res.data.intensity_profile_data;

      data?.map((record: ProfileData) => {
        if (record.profile.trim().toLowerCase() === "you")
          setYouProfileData({
            ...record,
            profile: "You",
          });
        else if (record.profile.trim().toLowerCase() === "competitors")
          setCompProfileData({
            ...record,
            profile: "Competitors",
          });
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(
        "Something went wrong during fetching competitive intensity profile data"
      );
    }
  };

  const fetchRoomsByProperty = async (propertyId: string) => {
    try {
      if (propertyOptions.length) {
        const roomProperties: any = await getPropertyRooms(
          { propertyId },
          token
        );
        setBedroomOptions([
          {
            label: "All",
            value: "",
          },
          ...roomProperties.data.bedroom.map((record: any) => ({
            label:
              record.bedrooms <= 1
                ? `${record.bedrooms} Bedroom`
                : `${record.bedrooms} Bedrooms`,
            value: `${record.bedrooms}`,
          })),
        ]);
      }
    } catch (error) {
      setLoader(false);
      toast.error(
        "Something went wrong during fetching rooms for property data"
      );
    }
  };

  const fetchDataOnPropertChange = async () => {
    try {
      renderRef.current = true;
      setLoader(true);
      setBedroomOptions([
        {
          label: "All",
          value: "",
        },
      ]);
      setSelectedRoom("");
      await Promise.all([
        fetchChartData(selectedProperty, ""),
        fetchCardsData(selectedProperty, ""),
        fetchTableData(selectedProperty, ""),
        fetchCompIntensityProfileData(selectedProperty, ""),
        fetchRecentLeaseData(selectedProperty, ""),
        fetchRoomsByProperty(selectedProperty),
      ]);
      renderRef.current = false;
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching data");
    }
  };

  const fetchDataOnRoomChange = async () => {
    try {
      setLoader(true);
      await Promise.all([
        fetchChartData(selectedProperty, selectedRoom),
        fetchCardsData(selectedProperty, selectedRoom),
        fetchTableData(selectedProperty, selectedRoom),
        fetchCompIntensityProfileData(selectedProperty, selectedRoom),
        fetchRecentLeaseData(selectedProperty, selectedRoom),
      ]);
    } catch (error) {
      setLoader(false);
      toast.error("Something went wrong during fetching data");
    }
  };

  const filterDataHandler = (value: string, i: number) => {
    const filteredChartData: ChartDataItem[] =
      data.selectedUniqueProperty === value
        ? chartData
        : chartData?.filter(
            (record: ChartDataItem) => record.property === value
          );
    const selectedUniqueProperty =
      value === data.selectedUniqueProperty ? "" : value;
    setData((prev: any) => ({
      ...prev,
      filteredChartData,
      selectedUniqueProperty,
      key: Math.random(),
      colors:
        filteredChartData.length === chartData.length
          ? legendColors
          : legendColors[i],
    }));
  };

  useEffect(() => {
    if (selectedProperty) {
      fetchDataOnPropertChange();
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (!renderRef.current) fetchDataOnRoomChange();
  }, [selectedRoom]);

  useEffect(() => {
    if (user) {
      getUserProperties();
    }
  }, [user]);

  const setFilterToggle = () =>
    setData((prev: any) => ({
      ...prev,
      key: Math.random(),
      showSelectedProperty: !prev?.showSelectedProperty,
    }));

  const handleSelectedProperty = (value: string) => {
    setselectedProperty(value);
    setData((prev) => ({ ...prev, showSelectedProperty: false }));
  };

  if (loader) {
    return <Loader />;
  }

  console.log("cardsData====>", cardsData);

  return (
    <>
      {/* PAGE HEADER SECTION */}
      {/* <Typography.Title
        level={5}
        style={{
          margin: 0,
          fontWeight: 500,
          fontSize: 30,
          color: "#2A313A",
        }}
      >
        Sub Market Snapshot
      </Typography.Title> */}
      <div className={classes.sectionHeader}>
        <Typography.Title
          level={5}
          style={{
            margin: 0,
            fontWeight: 500,
            fontSize: 18,
            color: "#2A313A",
          }}
        >
          Daily Pricing
        </Typography.Title>
        <div className={classes.filterContainer}>
          <Select
            placeholder="Property"
            options={propertyOptions}
            value={selectedProperty}
            onChange={handleSelectedProperty}
            style={{ maxWidth: "250px", minWidth: "150px" }}
          />
          <Select
            value={selectedRoom}
            options={[...bedroomOptions]}
            onChange={(val: string) => setSelectedRoom(val)}
            style={{ maxWidth: "250px", minWidth: "150px" }}
            allowClear
          />

          <Checkbox
            onChange={setFilterToggle}
            value={data?.showSelectedProperty}
            className={classes.checkfilter}
          >
            Include Selected Property
          </Checkbox>
        </div>
      </div>
      {/* FIRST SECTION  */}
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {/* SCATTER PLOT CHART  */}
          <div style={{ maxWidth: 1200 }} className={classes.container}>
            <ScatterPlot
              data={data}
              title="Scatterplot"
              height={500}
              filterDataHandler={filterDataHandler}
              key={data.key}
            />
          </div>
        </Col>

        {/* CARDS MAPPED FOR YOU AND COMPETITORS  */}
        <Col span={24}>
          <div
            className={classes.container}
            style={{
              maxWidth: 1200,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {cardsData.map((metric, index) => (
              <>
                <div
                  className={classes.cardContainer}
                  style={{
                    backgroundColor: "#f5f6f6",
                    width: "32%",
                  }}
                >
                  <Typography.Title
                    level={5}
                    style={{
                      margin: 0,
                      fontWeight: 500,
                      fontSize: 16,
                      padding: "15px",
                      textTransform: "capitalize",
                    }}
                  >
                    {metric.MetricName}
                  </Typography.Title>
                  <SubMCard
                    index={index}
                    color={metric.color}
                    icon={metric.icon}
                    size={33}
                    data={metric.Metrics}
                    name={metric.MetricName}
                  />
                </div>
              </>
            ))}
          </div>
        </Col>

        {/* CONCESSIONS TABLE  */}
        <Col span={24}>
          <div className={classes.container} style={{ maxWidth: 1200 }}>
            <Typography.Title
              level={5}
              style={{
                margin: 0,
                marginBottom: 16,
                fontWeight: 500,
                fontSize: 16,
                marginTop: 5,
              }}
            >
              Concessions
            </Typography.Title>
            <TableArea data={concessionsData} columns={columns} />
          </div>
        </Col>
      </Row>

      {/* SECOND SECTION  */}
      <Row gutter={[10, 10]}>
        {/* SECTION HEADER  */}
        <Typography.Title
          level={5}
          style={{
            margin: 0,
            marginBottom: 0,
            fontWeight: 500,
            fontSize: 18,
            marginTop: 20,
            marginLeft: 5,
          }}
        >
          Competitive Intensity Profile
        </Typography.Title>

        {/* CARDS AND PROGRESS MAPPED FOR YOU AND COMP  */}
        {[youProfileData, compProfileData].map(
          (record: ProfileData, index, arr) => {
            const maxAverageDaysToLease = Math.max(
              arr[0].avgDayToLease,
              arr[1].avgDayToLease
            );
            const widthPer =
              (record.avgDayToLease / maxAverageDaysToLease) * 100;

            return (
              <Col key={index} span={24}>
                <div className={classes.container} style={{ maxWidth: 1200 }}>
                  <Typography.Title
                    level={5}
                    style={{
                      margin: 0,
                      marginBottom: 10,
                      fontWeight: 500,
                      fontSize: 16,
                      marginTop: 5,
                    }}
                  >
                    {record?.profile}
                  </Typography.Title>
                  <div className={classes.flexContainer}>
                    <div className={classes.halfRows}>
                      <Typography.Title
                        level={5}
                        style={{
                          margin: 0,
                          marginBottom: 8,
                          fontWeight: 500,
                          fontSize: 16,
                          textTransform: "capitalize",
                        }}
                      >
                        leases
                      </Typography.Title>

                      <div className={classes.cardContainer}>
                        {[
                          {
                            value: record.oneWeek,
                            color: "#4361f9",
                            name: "1 Week",
                          },
                          {
                            value: record.oneMonth,
                            color: "#A15FFB",
                            name: "1 Month",
                          },
                          {
                            value: record.threeMonths,
                            color: "#FFB84C",
                            name: "3 Months",
                          },
                        ].map((item, index) => (
                          <SubICard
                            key={index}
                            index={index}
                            color={item.color}
                            name={item.name}
                            value={item.value}
                            size={33}
                          />
                        ))}
                      </div>
                    </div>
                    <div className={classes.halfRows}>
                      <Typography.Title
                        level={5}
                        style={{
                          margin: 0,
                          marginBottom: 8,
                          fontWeight: 500,
                          fontSize: 16,
                        }}
                      >
                        Average day to Lease
                      </Typography.Title>

                      <div className={classes.contentContainer}>
                        <span className={classes.value}>
                          {Math.round(record.avgDayToLease).toLocaleString()}
                        </span>
                        <div className={classes.progressTrail}>
                          <div
                            style={{ width: `${widthPer}%` }}
                            className={classes.progressStroke}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          }
        )}
      </Row>

      {/* THIRD SECTION  */}
      <Row gutter={[10, 10]}>
        {/* SECTION HEADING  */}
        <Typography.Title
          level={5}
          style={{
            margin: 0,
            marginBottom: 0,
            fontWeight: 500,
            fontSize: 18,
            marginTop: 20,
            marginLeft: 5,
          }}
        >
          Recent Leases
        </Typography.Title>

        {/* TABLES MAPPED FOR YOU AND COMPETITORS  */}
        {["You", "Competitors"].map((item, index) => (
          <Col key={index} span={24}>
            <div className={classes.container} style={{ maxWidth: 1200 }}>
              <Typography.Title
                level={5}
                style={{
                  margin: 0,
                  marginBottom: 16,
                  fontWeight: 500,
                  fontSize: 16,
                }}
              >
                {item}
              </Typography.Title>
              <TableArea
                data={item === "You" ? youRecentData : compRecentData}
                columns={item === "You" ? columnsYouTable : columnsCompTable}
              />
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default DailyPricing;
