import { useCallback, useEffect, useMemo } from "react";

import StatCard from "../../components/stats/StatCard";
import StatLoader from "./StatLoader";

import { useAppDispatch, useAppSelector } from "../../store/hooks";

import { getRenewalStats } from "../../store/stats/stats-slice";

interface RenewalProps {
  propertyID: string;
}

const defaultData = {
  title: "Renewals",
  barData: {
    value: 0,
    total: 0,
  },
  topData: {
    label: "Renewed",
    value: 0,
  },
  bottomData: {
    label: "Notice to Vacate",
    value: 0,
  },
};

const Renewal = ({ propertyID }: RenewalProps): React.ReactElement | null => {
  const dispatch = useAppDispatch();
  const renewals: any = useAppSelector((state) => state.stats?.renewals);
  console.log("renewals:", renewals);

  const selectedPropertyData = useMemo(() => {
    if (
      renewals?.isLoading ||
      (renewals?.data && renewals?.data?.length === 0)
    ) {
      return defaultData;
    }

    if (
      !propertyID &&
      !renewals.isLoading &&
      renewals?.data &&
      renewals?.data?.length > 0
    ) {
      return defaultData;
    }

    const selectedData = renewals?.data?.find(
      (property: any) => property.propertyID === propertyID
    );

    return selectedData ? selectedData : renewals.data[0];
  }, [propertyID, renewals]);

  const fetchData = useCallback(async () => {
    try {
      await dispatch(getRenewalStats());
    } catch (error) {
      console.log("error:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <StatLoader isLoading={renewals?.isLoading} />
      <StatCard
        data={selectedPropertyData ? selectedPropertyData : defaultData}
      />
    </>
  );
};

export default Renewal;
// const Renewal = ({ propertyID }: RenewalProps): ReactNode => {
//   const dispatch = useAppDispatch();
//   const renewals: any = useAppSelector((state) => state.stats.renewals);
//   // console.log('renewals:', renewals);

//   const selectedPropertyData = useMemo(() => {
//     if (renewals.isLoading || renewals.data.length === 0) {
//       return defaultData;
//     }

//     if (!propertyID && !renewals.isLoading && renewals.data.length > 0) {
//       return defaultData;
//     }

//     const selectedData = renewals.data.find(
//       (property: any) => property.propertyID === propertyID
//     );

//     return selectedData ? selectedData : renewals.data[0];
//   }, [propertyID, renewals]);

//   const fetchData = useCallback(async () => {
//     try {
//       await dispatch(getRenewalStats());
//     } catch (error) {
//       console.log('error:', error);
//     }
//   }, [dispatch]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   return (
//     <>
//       <StatLoader isLoading={renewals.isLoading} />
//       <StatCard
//         data={selectedPropertyData ? selectedPropertyData : defaultData}
//       />
//     </>
//   );
// };

// export default Renewal;
